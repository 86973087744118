@import 'https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap';

$filter-search-bg: #84b6ba;
$filter-name-bg: #8bc4b9;
$filter-user-bg: #8bc499;
$filter-date-bg: #9dc39a;
$filter-client-bg: #86c6ba;
$filter-status-bg: #84ad99;
$filter-funder-bg: #acba8e;
$filter-actions-bg: #bfcab7;
$filter-category-bg: #86c6ba;
$filter-change-bg: #b4d6ad;
$filter-save-bg: #f0e593;
$filter-delete-bg: #fe4c4a;
$filter-state-bg: #662d1c;
$filter-download-bg: #c5bfa7;
$filter-action-share-bg: #c3b39d;
$filter-action-export-bg: #ffd27c;
$filter-action-reset-bg: #feeb9e;
$filter-action-revert-bg: #e0cd7f;
$filter-date-modal-bg: #fff;
$filter-actions-modal-bg: #fff;

.editFiltersButton {
  padding-top: 8px;
  padding-bottom: 3px;
}

.filter-inputs-row {
  padding: 0 15px;
}

.btn-filter-actions > button,
.btn-filter-actions > div > button {
  // box-shadow: 0px -5px 5px -5px rgba(34, 60, 80, 0.6);
}

.btn-filter-actions .dropdown-item {
  display: block !important;
  padding: 5px !important;
  margin: 0 10px !important;
  clear: both;
  font-weight: 400 !important;
  height: auto !important;
  width: auto !important;
  white-space: nowrap;
}

.btn-filter-action-share {
  background-color: $filter-action-share-bg !important;

  &:hover {
    background-color: lighten($filter-action-share-bg, 10%) !important;
  }
}

.btn-filter-action-export {
  background-color: $filter-action-export-bg !important;

  &:hover {
    background-color: lighten($filter-action-export-bg, 5%) !important;
  }
}

.btn-filter-action-reset {
  background-color: $filter-action-reset-bg !important;

  &:hover {
    background-color: lighten($filter-action-reset-bg, 10%) !important;
  }
}

.btn-filter-action-revert {
  background-color: $filter-action-revert-bg !important;

  &:hover {
    background-color: lighten($filter-action-revert-bg, 10%) !important;
  }
}

.filter-card-container {
  margin-bottom: 0;
}

.filter-action-icon {
  margin-top: 5px;
  margin-right: 10px;
  color: #0b5d37;
}

.filter-action-title {
  font-size: 12px;
  color: #0b5d37;
}

.filter-card {
  transition:
    color 0.15s ease-in-out,
    background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;

  h4 {
    font-family: Raleway !important;
    font-weight: 700;
  }

  .filter-card-content {
    font-family: Raleway !important;
    font-weight: 600;
    font-size: 10pt;
    color: #292f3c;
  }
}

.bg-filter-user {
  background: lighten($filter-user-bg, 15%) !important;

  &:hover {
    background-color: lighten($filter-user-bg, 30%) !important;
  }
}

.bg-filter-client {
  background: $filter-client-bg !important;

  &:hover {
    background-color: lighten($filter-client-bg, 10%) !important;
  }
}

.bg-filter-search {
  background: $filter-search-bg !important;

  &:hover {
    background-color: lighten($filter-search-bg, 10%) !important;
  }
}

.bg-filter-change {
  background: $filter-change-bg !important;

  &:hover {
    background-color: lighten($filter-change-bg, 10%) !important;
  }
}

.bg-filter-save {
  background: $filter-save-bg !important;

  &:hover {
    background-color: lighten($filter-save-bg, 10%) !important;
  }
}

.bg-filter-delete {
  background: $filter-delete-bg !important;

  &:hover {
    background-color: lighten($filter-delete-bg, 10%) !important;
  }
}

.bg-filter-funder {
  background: lighten($filter-funder-bg, 15%) !important;

  &:hover {
    background-color: lighten($filter-funder-bg, 30%) !important;
  }
}

.bg-filter-name {
  background: $filter-name-bg !important;

  &:hover {
    background-color: lighten($filter-name-bg, 20%) !important;
  }
}

.bg-filter-date {
  background: $filter-date-bg !important;

  &:hover {
    background-color: lighten($filter-date-bg, 20%) !important;
  }
}

.bg-filter-status {
  background: $filter-status-bg !important;

  &:hover {
    background-color: lighten($filter-status-bg, 20%) !important;
  }
}

.bg-filter-actions {
  background: $filter-actions-bg !important;

  &:hover {
    background-color: lighten($filter-actions-bg, 20%) !important;
  }
}

.bg-filter-state {
  background: $filter-state-bg !important;

  &:hover {
    background-color: lighten($filter-state-bg, 20%) !important;
  }
}

.bg-filter-category {
  background: $filter-category-bg !important;

  &:hover {
    background-color: lighten($filter-category-bg, 20%) !important;
  }
}

.bg-filter-download {
  background: $filter-download-bg !important;

  &:hover {
    background-color: lighten($filter-download-bg, 20%) !important;
  }
}

.bg-filter-date-modal {
  background-color: rgb(0 0 0 / 50%);

  .modal-content {
    background: $filter-date-modal-bg !important;
  }

  .modal-header {
    background-color: transparent !important;
    border-bottom: 1px solid rgb(0 0 0 / 25%);

    .modal-title {
      font-family: Raleway !important;
      font-weight: 600;
      user-select: none;
    }

    .close {
      color: 1px solid rgb(0 0 0 / 25%);

      &:hover {
        color: 1px solid rgb(0 0 0 / 50%);
      }
    }
  }
}

.bg-filter-actions-modal,
.bg-filter-users-modal,
.bg-filter-clients-modal,
.bg-filter-program-names-modal,
.bg-filter-funders-modal,
.bg-filter-statuses-modal,
.bg-filter-categories-modal,
.bg-filter-set-delete-modal,
.bg-filter-set-create-modal,
.bg-filter-set-search-modal,
.bg-filter-set-save-modal {
  background-color: rgb(255 255 255 / 64%);
  -webkit-backdrop-filter: blur(5px);

  .modal-content {
    background: $filter-actions-modal-bg !important;
  }

  .modal-header {
    background-color: transparent !important;
    border-bottom: 1px solid rgb(0 0 0 / 25%);

    .modal-title {
      font-family: Raleway !important;
      font-weight: 600;
      user-select: none;
    }

    .close {
      color: 1px solid rgb(0 0 0 / 25%);

      &:hover {
        color: 1px solid rgb(0 0 0 / 50%);
      }
    }
  }
}

.bg-filter-users-modal,
.bg-filter-clients-modal,
.bg-filter-funders-modal,
.bg-filter-program-names-modal {
  .modal-header {
    border-color: $custom-hr-color !important;
  }

  .modal-content {
    div[class*='rbt-input focus'] {
      outline: 2px solid $primary-blue-color !important;
      border-color: $primary-blue-color !important;
      box-shadow: none;
    }

    & input {
      font-weight: unset;
      color: $custom-text-regular;

      &:active,
      &:focus {
        color: $custom-text-active !important;
      }
    }
  }
}

.filter {
  &-top-row {
    max-width: unset;
  }

  &-section-buttons {
    display: flex;
    justify-content: space-between !important;
    align-items: center !important;
    // border-bottom: 1px solid $primary-blue-color;
  }
}

.filter-action-row {
  display: flex;
  flex-direction: row;
  align-items: center;

  & div[class*='b-r-4 filter-card card-body card-body'] {
    border-radius: 10px !important;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 7px 10px;
    box-shadow: unset !important;
    margin-left: 5px;
    height: 38px;
  }

  & div[role='button'] {
    border-radius: 10px !important;
    padding: 0;
    box-shadow: unset !important;
  }

  & div[class*='media static-top-widget'] {
    display: flex;
    flex-direction: row;
    padding: unset !important;
    justify-content: center;
    align-self: center;

    & svg {
      color: #0b5d37;
      height: 24px;
      width: 24px;
      margin: 0;
      margin-right: 5px;
      align-self: center;
    }

    & h4 {
      font-size: 16px;
      font-weight: 600;
      color: $primary-black-color;
      font-family: $font-nunito !important;
      text-align: center;
      line-height: 15px;
      margin-top: 5px !important;
    }
  }

  & div[class*='media'] {
    padding: unset;
  }

  & div[class*='filter-card-content'] {
    font-size: 16px !important;
    font-weight: 600 !important;
  }

  & .card {
    border-radius: unset !important;
  }
}

div[class*='btn-filter-actions btn-group'],
.filter-section-buttons {
  // margin-top: 1rem;

  a {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  & button,
  & a {
    border: unset;
    background-color: $primary-black-color;
    font-weight: 600;
    font-family: $font-nunito;
    border-radius: 10px !important;
    margin: 0 5px;
    padding: 0 10px;
    color: $primary-black-color;
    height: 38px;
    font-size: 16px;
    line-height: 15px;
    text-align: center;

    $button-colors: $filter-user-bg, $filter-status-bg, $filter-funder-bg, $filter-date-bg,
      $filter-search-bg, $filter-category-bg, $filter-client-bg, $filter-actions-bg, $filter-name-bg;

    @for $i from 1 through length($button-colors) {
      &:nth-child(#{length($button-colors)}n + #{$i}) {
        background-color: nth($button-colors, $i) !important;
      }
    }

    & div[class*='filter-action-title'] {
      all: unset !important;
    }
  }
}
