.review {
  font-family: $font-nunito !important;

  & input:first-of-type {
    color: #323232 !important;
    font-weight: 700 !important;
  }

  > span {
    font-size: 14px !important;
    color: #2c4474 !important;
  }
}

.reports-knob-header,
.report-stats-header {
  font-family: $font-nunito !important;
  color: $primary-black-color !important;
  font-weight: 600 !important;
}

.report-knob-data,
.report-stat-data {
  font-family: $font-nunito !important;
  // color: $primary-black-color !important;
  font-weight: 600 !important;
}
