.client-contact-wrapper {
  border-radius: 12px;
  border-width: 1px;
  background: #ffffff;
  border: 1px solid #eff1f6;
  padding: 32px 20px;

  .client-contact-heading {
    font-family: Raleway;
    font-weight: 700;
    font-size: 20px;
    color: #323232;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 32px;

    .show-hide-text {
      font-family: Raleway;
      font-weight: 600;
      font-size: 14px;
      line-height: 16.44px;
      color: #2c4474;
    }
  }
}

.common-input .rbt-input {
  border-radius: 4px;
  padding-top: 8px;
  padding-right: 12px;
  padding-bottom: 8px;
  padding-left: 12px;
  font-weight: 600;
  border: 1px solid #9fadc9 !important;
  background: #ffffff;
  color: #2c4474 !important;
}

.common-input .rbt-input::placeholder {
  font-family: Raleway;
  font-weight: 600;
  font-size: 14px;
  line-height: 19.6px;
  color: #9fadc9 !important;
}

.common-input .form-label {
  font-family: Raleway;
  font-weight: 600;
  font-size: 14px;
  line-height: 19.6px;
  color: #50535b !important;
}

.common-input .form-control {
  border-radius: 4px;
  padding-top: 8px;
  padding-right: 12px;
  padding-bottom: 8px;
  padding-left: 12px;
  font-weight: 600;
  border: 1px solid #9fadc9 !important;
  background: #ffffff;
  color: #2c4474 !important;
}

.common-input .form-control::placeholder {
  font-family: Raleway;
  font-weight: 600;
  font-size: 14px;
  line-height: 19.6px;
  color: #9fadc9 !important;
}

.common-input .form-label {
  font-family: Raleway;
  font-weight: 600;
  font-size: 14px;
  line-height: 19.6px;
  color: #50535b !important;
}
