.file-uploader {
  height: 90%;
  border-radius: 12px;
  border-width: 1px;
  background: #f9fafb;
  border: 1px dotted #eff1f6 !important;
}

.client-general-fields {
  width: 100%;
  border-radius: 12px;
  border-width: 1px;
  background: #ffffff;
  border: 1px solid #eff1f6;
  padding: 32px 20px;

  .general-fields-w {
    width: 75% !important;

    .field-heading-wraper {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      margin-bottom: 10px;
      gap: 6px;

      .field-heading {
        font-family: Raleway;
        font-weight: 600;
        font-size: 14px;
        line-height: 19.6px;
        letter-spacing: 0%;
        color: #2c4474;
      }

      .field-value {
        font-family: Raleway;
        font-weight: 700;
        font-size: 14px;
        line-height: 16px;
        letter-spacing: 0%;
        color: #252933;
        margin-bottom: 1rem;
      }
    }
  }

  .main-client-heading {
    font-family: Raleway;
    font-weight: 700;
    font-size: 20px;
    color: #323232;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 32px;
  }
}

.show-hide-text {
  font-family: Raleway;
  font-weight: 600;
  font-size: 14px;
  line-height: 16.44px;
  color: #2c4474;
}

.heading-gb {
  font-family: Raleway;
  font-weight: 600;
  font-size: 20px;
  line-height: 20px;
  letter-spacing: 0%;
  color: #252933;
}

.common-input .rbt-input {
  border-radius: 4px;
  padding-top: 8px;
  padding-right: 12px;
  padding-bottom: 8px;
  padding-left: 12px;
  font-weight: 600;
  border: 1px solid #9fadc9 !important;
  background: #ffffff;
  color: #2c4474 !important;
}

.common-input .rbt-input::placeholder {
  font-family: Raleway;
  font-weight: 600;
  font-size: 14px;
  line-height: 19.6px;
  color: #9fadc9 !important;
}

.common-input .form-label {
  font-family: Raleway;
  font-weight: 600;
  font-size: 14px;
  line-height: 19.6px;
  color: #50535b !important;
}

.common-input .form-control {
  border-radius: 4px;
  padding-top: 8px;
  padding-right: 12px;
  padding-bottom: 8px;
  padding-left: 12px;
  font-weight: 600;
  border: 1px solid #9fadc9 !important;
  background: #ffffff;
  color: #2c4474 !important;
}

.common-input .form-control::placeholder {
  font-family: Raleway;
  font-weight: 600;
  font-size: 14px;
  line-height: 19.6px;
  color: #9fadc9 !important;
}

.common-input .form-label {
  font-family: Raleway;
  font-weight: 600;
  font-size: 14px;
  line-height: 19.6px;
  color: #50535b !important;
}

.currency-text {
  font-family: Raleway;
  font-weight: 600;
  font-size: 12px;
  line-height: 16.8px;
  color: #2c4474;
}

.custom-fields-wrapper {
  width: 100%;
  border-radius: 12px;
  border-width: 1px;
  padding: 32px 20px;
  background: #ffffff;
  border: 1px solid #eff1f6;

  .hide-show-wrapper {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;

    .show-hide-text {
      font-family: Raleway;
      font-weight: 600;
      font-size: 14px;
      line-height: 16.44px;
      color: #2c4474;
    }
  }
}

//create and details pages header

.breadcrumb-list {
  font-family: Raleway;
  font-weight: 600;
  font-size: 14px;
  line-height: 16.8px;
  cursor: pointer;
  color: #50535b;
}

.back-button {
  width: 34px;
  height: 34px;
  background: #eff1f6;
  border-radius: 6px;
  padding: 8px 16px;
}

.heading {
  font-family: Raleway;
  font-weight: 700;
  font-size: 28px;
  line-height: 28px;
  letter-spacing: 0%;
  color: #252933;
}

.discard-btn {
  border-radius: 6px;
  padding: 8px 16px;
  background-color: #eff1f6 !important;
  color: #252933 !important;
  border: none;
}

.discard-btn:hover {
  background: #e0e3eb !important;
}

.sub-heading {
  font-family: Raleway;
  font-weight: 600;
  font-size: 14px;
  line-height: 19.6px;
  letter-spacing: 0%;
  color: #50535b;
}

.selectorbg {
  background-color: #2c4474;
  color: #ffffff !important;
  padding: 2px 4px;
  border-radius: 2px;
  display: inline-flex;
  align-items: center;
  gap: '4px';
}
