.details-main-layout {
  .left-column {
    .general-detail-section,
    .financial-detail-section {
      padding: 32px 20px;
      border-radius: 12px;
      background: #ffffff;
      border: 1px solid #eff1f6;
      box-shadow: 0 0 15px 10px #f7f7f7;
    }
  }

  .right-column {
    padding: 32px 20px;
    border-radius: 12px;
    background: #ffffff;
    border: 1px solid #eff1f6;
    box-shadow: 0 0 15px 10px #f7f7f7;
  }
}

.grant-summary-file {
  font-family: Raleway;
  font-weight: 600;
  font-size: 14px;
  letter-spacing: 0%;
  color: #5f77a5;
}

.show-hide-historyBar {
  padding: 32px 20px;
  border-radius: 12px;
  background: #ffffff;
  border: 1px solid #eff1f6;
  box-shadow: 0 0 15px 10px #f7f7f7;
}

.general-fields-wrapper {
  width: 100%;
  border-radius: 12px;
  border-width: 1px;
  background: #ffffff;
  border: 1px solid #eff1f6;
  padding: 20px;
}

.field-heading-wraper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 5px;

  .field-heading {
    font-family: Raleway;
    font-weight: 600;
    font-size: 14px;
    line-height: 19.6px;
    letter-spacing: 0%;
    color: #2c4474;
  }

  .field-value {
    font-family: Raleway;
    font-weight: 700;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 0%;
    color: #252933;
    margin-bottom: 1rem;
  }
}

.heading-gb {
  font-family: Raleway;
  font-weight: 600;
  font-size: 20px;
  line-height: 20px;
  letter-spacing: 0%;
  color: #252933;
}

.common-input .rbt-input {
  border-radius: 4px !important;
  padding-top: 8px;
  padding-right: 12px;
  padding-bottom: 8px;
  padding-left: 12px;
  font-weight: 600;
  border: 1px solid #9fadc9 !important;
  background: #ffffff;
  color: #2c4474 !important;
  border-top-right-radius: 4px !important;
  border-bottom-right-radius: 4px !important;
}

.common-input .rbt-input::placeholder {
  font-family: Raleway;
  font-weight: 600;
  font-size: 14px;
  line-height: 19.6px;
  color: #9fadc9 !important;
}

.common-input .form-label {
  font-family: Raleway;
  font-weight: 600;
  font-size: 14px;
  line-height: 19.6px;
  color: #50535b !important;
}

.common-input .form-control {
  border-radius: 4px !important;
  border-top-right-radius: 4px !important;
  border-bottom-right-radius: 4px !important;
  padding-top: 8px;
  padding-right: 12px;
  padding-bottom: 8px;
  padding-left: 12px;
  font-weight: 600;
  border: 1px solid #9fadc9 !important;
  background: #ffffff;
  color: #2c4474 !important;
}

.common-input .form-control::placeholder {
  font-family: Raleway;
  font-weight: 600;
  font-size: 14px;
  line-height: 19.6px;
  color: #9fadc9 !important;
}

.common-input .form-label {
  font-family: Raleway;
  font-weight: 600;
  font-size: 14px;
  line-height: 19.6px;
  color: #50535b !important;
}

.currency-text {
  font-family: Raleway;
  font-weight: 600;
  font-size: 12px;
  line-height: 16.8px;
  color: #2c4474;
}

.custom-fields-wrapper {
  width: 100%;
  border-radius: 12px;
  border-width: 1px;
  padding: 32px 20px;
  background: #ffffff;
  border: 1px solid #eff1f6;
  box-shadow: 0 0 15px 10px #f7f7f7;

  .hide-show-wrapper {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;

    .show-hide-text {
      font-family: Raleway;
      font-weight: 600;
      font-size: 14px;
      line-height: 16.44px;
      color: #2c4474;
    }
  }
}

//create and details pages header

.breadcrumb-list {
  font-family: Raleway;
  font-weight: 600;
  font-size: 14px;
  line-height: 16.8px;
  cursor: pointer;
  color: #50535b;
}

.back-button {
  width: 34px;
  height: 34px;
  background: #eff1f6;
  border-radius: 6px;
  padding: 8px 16px;
}

.application-edit-heading {
  font-family: Raleway;
  font-weight: 700;
  font-size: 20px;
  line-height: 28px;
  letter-spacing: 0%;
  color: #323232;
  margin-bottom: 30px;
}

.discard-btn {
  border-radius: 6px;
  padding: 8px 16px;
  background-color: #eff1f6 !important;
  color: #252933 !important;
  border: none;
}

.discard-btn:hover {
  background: #e0e3eb !important;
}

.sub-heading {
  font-family: Raleway;
  font-weight: 600;
  font-size: 14px;
  line-height: 19.6px;
  letter-spacing: 0%;
  color: #50535b;
}

.selectorbg {
  background-color: #2c4474;
  color: #ffffff !important;
  padding: 2px 4px;
  border-radius: 2px;
  display: inline-flex;
  align-items: center;
  gap: '4px';
}

.history-tagline {
  font-family: Raleway;
  font-weight: 500;
  font-size: 16px;
  line-height: 100%;
  color: #50535b;
  margin-bottom: 10px;
}

.common-input .rbt-token {
  background-color: #2c4474;
  color: #fff;
}

.custom-status-dropdown {
  background-color: transparent !important;

  button {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: transparent !important;
    border: 1px solid #9fadc9;

    &:hover {
      background-color: transparent !important;
      border: 1px solid #9fadc9;
    }

    &:focus {
      outline: none;
      background-color: transparent !important;
    }

    &:active {
      background-color: transparent !important;
      border-color: #7d8fb3 !important;
    }

    &::after {
      border-top-color: #3c5d9c;
      margin-left: 8px;
    }
  }

  .status-text {
    padding: 2px 6px;
    border-radius: 2px;
  }
}
