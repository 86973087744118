.client-files-wrapper {
  border-radius: 12px;
  border-width: 1px;
  background: #ffffff;
  border: 1px solid #eff1f6;
  padding: 32px 20px;

  .client-file-heading {
    font-family: Raleway;
    font-weight: 700;
    font-size: 20px;
    color: #323232;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 32px;

    .show-hide-text {
      font-family: Raleway;
      font-weight: 600;
      font-size: 14px;
      line-height: 16.44px;
      color: #2c4474;
    }
  }

  .custom-dropzone .dzu-dropzone {
    border: 2px dotted #9fadc9 !important;
    background-color: #f9fafb !important;
    min-height: 54px !important;
    gap: 12px;
    border-radius: 4px;
    border-width: 1px;
    padding: 9px 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
  }

  .dropzone-content {
    display: flex;
    align-items: center;
    font-weight: 600;
    font-size: 14px;
    font-weight: bold;
    color: #2c4474;
  }

  .upload-icon {
    font-size: 24px !important;
    color: #2c4474;
  }
}
