.custom-auth-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #ffffff;
  border: 1px solid #eff1f6;
  border-radius: 12px;
  padding: 48px;
  gap: 16px;

  .custom-header {
    display: flex;
    flex-direction: column;
    gap: 10px;

    .custom-title {
      font-family: Raleway;
      font-weight: 600;
      font-size: 20px;
      line-height: 26px;
      letter-spacing: 0%;
      color: #2c4474;
    }

    .custom-subtitle {
      font-family: Raleway;
      font-weight: 600;
      font-size: 14px;
      line-height: 19.6px;
      letter-spacing: 0%;
      color: #2c4474;
    }
  }

  .custom-form-group {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    gap: 3px;

    .custom-label {
      font-family: Raleway;
      font-weight: 600;
      font-size: 14px;
      line-height: 19.6px;
      letter-spacing: 0%;
      color: #50535b;
    }

    .custom-input {
      border-radius: 4px;
      border-width: 1px;
      padding: 8px 12px;
      border: 1px solid #9fadc9;
      color: #2c4474;
      background: #ffffff;
      font-size: 14px;
      font-family: Raleway;
      font-weight: 600;

      &::placeholder {
        font-family: Raleway;
        font-weight: 600;
        font-size: 14px;
        line-height: 19.6px;
        color: #9fadc9;
      }
    }

    .custom-error {
      color: red;
      font-family: Raleway;
      font-weight: 600;
      font-size: 14px;
    }

    &.password-group {
      position: relative;

      .password-input-container {
        position: relative;
        width: 100%;

        .password-toggle-icon {
          position: absolute;
          right: 12px;
          top: 60%;
          transform: translateY(-50%);
          cursor: pointer;
          color: #9fadc9;
        }
      }
    }

    .password-label-container {
      width: 100%;
      display: flex;
      justify-content: space-between !important;

      .custom-forgot-password {
        font-family: Raleway;
        font-weight: 600;
        font-size: 14px;
        line-height: 16.44px;
        text-decoration: underline;
        text-decoration-style: solid;
        text-decoration-thickness: Auto;
        cursor: pointer;
        color: #2c4474;
        display: flex;
        align-self: flex-start;
      }
    }
  }

  .custom-button-group {
    min-width: 100%;

    button {
      width: 100%;
    }
  }
}
