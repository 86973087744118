/**  =====================
    26. Form CSS Start
==========================  **/
form {
  &.needs-validation {
    .form-row {
      input {
        ~ span {
          color: $danger-color;
        }
      }
    }

    .input-group {
      span {
        width: 100%;
      }
    }

    &.tooltip-validation {
      &.validateClass {
        .form-row {
          input {
            ~ span {
              padding: 0.25rem 0.5rem;
              margin-top: 0.1rem;
              font-size: 0.875rem;
              line-height: 1.5;
              color: $white;
              background-color: rgba($danger-color, 0.9);
              border-radius: 0.25rem;
            }
          }
        }
      }
    }
  }
}

.height-35 {
  height: 35px !important;
}

.form-control {
  height: auto;
  line-height: 21px;
  text-overflow: ellipsis;

  &.btn {
    width: auto;
  }
}

.theme-form {
  .form-control {
    :focus {
      box-shadow: 0 0 0 0.2rem rgb(171 140 228 / 5%);
    }
  }

  &.sm-form {
    .form-group {
      margin-bottom: $sm-form-margin-bottom;
    }
  }

  .form-group {
    margin-bottom: $form-group-margin-bottom;

    textarea {
      border-color: $form-input-border-color;

      &::-webkit-input-placeholder {
        color: $form-placeholder-color;
        font-size: $form-placeholder-font-size;
        letter-spacing: $form-placeholder-letter-spacing;
        font-weight: $form-placeholder-font-weight;
      }
    }

    input[type='text'],
    input[type='email'],
    input[type='search'],
    input[type='password'],
    input[type='number'],
    input[type='tel'],
    input[type='date'],
    input[type='datetime-local'],
    input[type='time'],
    input[type='datetime-local'],
    input[type='month'],
    input[type='week'],
    input[type='url'],
    input[type='file'],
    select {
      border-color: $form-input-border-color;
      background-color: $form-input-bg-color;
      font-size: $form-placeholder-font-size;
      color: $form-placeholder-color;
      font-family: $font-work-sans, $font-serif;

      &::-webkit-input-placeholder {
        color: $form-placeholder-color;
        font-size: $form-placeholder-font-size;
        letter-spacing: $form-placeholder-letter-spacing;
        font-weight: $form-placeholder-font-weight;
      }
    }

    select.form-control:not([size], [multiple]) {
      border-color: $form-input-border-color;
      font-size: $form-placeholder-font-size;
    }
  }

  .checkbox {
    label {
      padding-left: 10px;
    }
  }

  .form-divider {
    position: relative;
    clear: both;
    height: 0;
    margin: 30px 0;
    border-top: 1px solid $light-semi-gray;

    &::before {
      position: absolute;
      top: -11px;
      left: 40%;
      margin-left: -10px;
      padding: 0 5px;
      content: 'Or Signup With';
      color: $theme-font-color;
      background: $white;
    }
  }

  .login-divider {
    position: relative;
    clear: both;
    height: 0;
    margin: 30px 0;
    border-top: 1px solid $light-semi-gray;

    &::before {
      position: absolute;
      top: -11px;
      left: 40%;
      margin-left: -10px;
      padding: 0 5px;
      color: $theme-font-color;
      background: $white;
      content: 'Or Login With';
    }
  }
}

.form-inline {
  .form-group {
    margin-right: 15px;

    .col-form-label {
      margin-right: 5px;
    }
  }
}

.form-label-align-right {
  label {
    text-align: right;
    padding-top: 17px;
    font-weight: 500;
  }
}

.custom-file-label {
  line-height: 1.8;

  &::after {
    line-height: 1.8;
  }
}

.generate-memo-form {
  color: #252933;
}

.client-page-form {
  & input {
    font-size: $form-control-font-size !important;
  }
}

.edit-client-page-form {
  max-width: 90%;
}

.client-contacts-form {
  & input {
    font-size: $form-control-font-size !important;
  }
}

.dzu-dropzone {
  border-color: #ced4d8 !important;
  overflow: hidden;
}

.dz-message {
  & input,
  label {
    color: $primary-blue-color !important;
  }
}

.application-detail-form {
  & input {
    font-size: $form-control-font-size !important;
    padding: 10px;
    border-width: 2px !important;
    border-radius: 5px;
    height: 46px;
  }

  & button {
    min-height: 46px;
  }

  & button[class*='close-icon']::after {
    background-color: $primary-blue-color;
  }

  & button[class*='application-memo'] {
    width: 100%;
  }

  & button[class*='rbt-close rbt-token-remove-button'] {
    top: -12px;
    right: -3px;
    padding: 8px 10px;
  }

  & button[class*='application-detail-edit'] {
    border-radius: 5px !important;
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
  }

  & div[class*='rbt-input-multi form-control rbt-input'] {
    padding: 10px;
    border-width: 2px;
    border-radius: 5px;
    height: unset;

    & input {
      height: unset;
    }
  }

  & div[class*='dropdown'] {
    & button {
      height: 46px;
      border-width: 2px;
    }
  }

  & input[class*='form-control digits'] {
    height: 46px;
    border-radius: 5px;
  }

  & input[id*='Name'] {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  // & div[class*='input-group'] {
  //   & input[inputmode='decimal'] {
  //     border-radius: 0 !important;
  //   }
  // }

  .application-arrows {
    svg {
      height: 46px;
    }
  }

  .react-datepicker__close-icon {
    padding: 0 10px 0 0;
  }
}

.program-detail-form {
  & input {
    font-size: $form-control-font-size !important;
    padding: 0.4rem 0.75rem;
  }

  & button {
    min-height: 38px;
  }

  & button[class*='close-icon']::after {
    background-color: $primary-blue-color;
  }
}

.custom-field-input {
  & input {
    color: $custom-text-regular;

    &::placeholder {
      color: $custom-text-regular;
    }

    &:active {
      color: $custom-text-active;
    }
  }
}

.create-app-form {
  all: unset;

  // padding: 1rem;
  height: 500px;
  background-color: $white !important;

  & button {
    font-size: $form-control-font-size;
  }
}

.add-app-form {
  .react-datepicker__close-icon {
    padding: 0 10px 0 0;
  }
}

.create-app-inputs {
  div[class*='rbt-input focus'] {
    outline: 2px solid $primary-blue-color !important;
    border-color: $primary-blue-color !important;
    box-shadow: none;
  }

  & input {
    &:active,
    &:focus {
      color: $custom-text-active !important;
    }
  }
}

.create-app-textarea {
  & textarea {
    &:active,
    &:focus {
      color: $custom-text-active !important;
      outline: 2px solid $primary-blue-color !important;
      border-color: $primary-blue-color !important;
      box-shadow: none;
    }
  }
}

.report-chart-form {
  font-family: $font-nunito !important;
}

.custom-dropdown {
  // max-width: 350px;
  & input[type='button'] {
    cursor: pointer;
    padding: 2px 20px;
    border-radius: 5px;
    background-color: $white;
    border: 2px solid $primary-blue-color;
    font-family: $font-work-sans !important;

    // max-width: 200px;
    width: 100%;
    font-weight: 600;
    color: $primary-blue-color;
  }

  & button[class*='close'] {
    top: -5px !important;
    right: 1px !important;
  }

  & input[class*='custom-dropdown_header--active'] {
    background-color: $primary-blue-color;
    color: $white;
    padding: 5px 20px;
    border-bottom: unset;
    border-bottom-left-radius: unset;
    border-bottom-right-radius: unset;
    max-width: unset;
    width: 100%;
  }

  &__inputs {
    padding: unset !important;
    margin-top: 3px !important;
  }

  &__list {
    &--disabled {
      display: none;
    }

    &--active {
      display: flex;
      border: 2px solid $primary-blue-color;
      border-radius: 5px;
      border-top-left-radius: unset;
      border-top-right-radius: unset;
    }
  }

  &__items {
    display: flex;
    flex-direction: column;
  }

  &__item {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin: 4px 0;
  }

  &__select {
    &--button {
      color: $custom-hr-color;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 35px;

      &--active {
        border-bottom-right-radius: unset !important;
        border-bottom-left-radius: unset !important;
      }
    }
  }

  &__bottom {
    display: flex;
    align-items: center;

    & button {
      margin: 0 2px;
      height: 38px !important;
    }
  }

  & div[class*='rbt-input-multi form-control rbt-input'] {
    border: unset;
  }

  &--Search {
    & input {
      width: 45%;
    }

    & ul {
      margin: 0 5px;
      max-width: 100px;
      width: 100%;
    }

    & li {
      text-align: center;
      border: 1px solid $custom-hr-color;
      border-radius: 5px;

      &:not(:first-of-type) {
        border-top-right-radius: unset;
        border-top-left-radius: unset;
        border-top: unset;
        padding: 4px 0;
        border-bottom-right-radius: unset;
        border-bottom-left-radius: unset;
      }

      &:first-of-type {
        height: 35px;
      }
    }

    & button {
      width: 10%;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 35px;
    }

    & div[class*='rbt-input-multi form-control rbt-input'] {
      border: 1px solid #ced4da;
    }

    & .custom-dropdown__items {
      padding: 5px;
    }
  }

  &--State,
  &--Date,
  &--Show {
    // max-width: 400px;

    & label {
      display: none;
    }

    & div {
      margin: unset !important;
    }

    button {
      padding-left: 0 !important;
    }

    .container-fluid {
      padding-left: 0;

      div {
        padding-left: 0;
      }

      .row {
        padding-left: 15px;
      }
    }
  }
}

div[class*='custom-dropdown--Search'] {
  .custom-dropdown__item {
    margin: 0 0 5px;

    .custom-dropdown__select--button {
      cursor: pointer;
    }

    a.dropdown-item {
      overflow: auto;
      text-overflow: initial;
      white-space: break-spaces;
    }
  }

  .custom-dropdown__bottom button {
    line-height: 1;
    padding: 10px;
    height: auto !important;
  }
}

div[class*='custom-dropdown--Assigned-To'],
div[class*='custom-dropdown--Assigned'] {
  & div[class*='rbt-input focus'] {
    border: none !important;
    outline: none !important;
    box-shadow: none !important;

    &:focus {
      border: none !important;
      outline: none !important;
    }
  }
}

div[class*='custom-dropdown--Status'],
div[class*='custom-dropdown--Category'],
div[class*='custom-dropdown--State'],
div[class*='custom-dropdown--Show'] {
  & button[type='button'] {
    border: unset;
  }

  & .custom-dropdown__inputs {
    margin: unset !important;

    & div:first-of-type {
      // border: none !important;
      outline: none !important;

      &:focus {
        // border: none !important;
        outline: none !important;
      }
    }
  }

  & select {
    border-top-right-radius: unset !important;
    border-top-left-radius: unset !important;

    // border: unset !important;
    outline: none !important;

    &:focus {
      // border: unset !important;
      outline: none !important;
    }
  }

  .dropdown-item {
    padding-left: 0;
    min-width: auto;
  }
}

.date-picker-button {
  width: 100% !important;
  height: 100% !important;
}

.report-inputs {
  margin-top: 10px;
}

.tooltip [class$='inner'] {
  max-width: 100%;
  margin-bottom: -30px;
  background-color: #e9ecef;
  width: 309px !important;
  color: $primary-black-color;
  font-family: $font-nunito;
  font-size: 16px;
  border: 1px solid #ced4da;
  text-align: left !important;

  & span {
    display: flex;
    align-self: flex-start;
    width: 100%;
  }

  & ul {
    width: 1000%;
    list-style: circle;
    list-style-position: inside;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;

    & li {
      width: 100%;
    }
  }
}

.tooltip [class$='arrow'] {
  margin-bottom: -30px;
}

.application-form-header {
  display: flex;
  justify-content: space-between;
  align-items: center;

  h5 {
    margin: 0;
  }
}

.application-header-controls {
  display: flex;

  .eligibility-status {
    margin-right: 10px;
  }
}

.application-edit-progname {
  & input[placeholder*='My Grant Program'] {
    border-top-right-radius: 5px !important;
    border-bottom-right-radius: 5px !important;
  }
}

.render-custom-tooltip {
  width: 100%;
}

.history-bar {
  margin-top: 10px;
  margin-bottom: 10px;

  .history-show {
    padding: unset;
    min-width: 150px;
  }

  .arrow {
    border: solid $white;
    border-width: 0 1.5px 1.5px 0;
    display: inline-block;
    padding: 4px;
    margin: 0 0 2px 10px;

    &-down {
      transform: rotate(45deg);
    }

    &-up {
      margin-bottom: -2px;
      transform: rotate(-135deg);
    }
  }

  .action-column-changes {
    min-height: 47px;
    display: flex;
    list-style-type: none;
    text-transform: capitalize;
    flex-wrap: wrap;

    li::after {
      content: ',\00a0';
    }

    li:last-of-type {
      &::after {
        content: '\00a0';
      }
    }
  }

  .logs-changes-row {
    vertical-align: middle;
    font-family: $font-nunito;

    & li {
      padding: 3px;
    }

    .changed-field {
      color: $primary-blue-color;
      padding: 15px;
      font-weight: bold;
      text-transform: capitalize;
    }

    .column-arrow {
      margin: 0 10px;
      color: $primary-blue-color;
      border-width: 0 2.5px 2.5px 0;
      border-color: #5f77a5;
      transform: rotate(-45deg);
    }
  }

  .no-actions {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.eligibility-status {
  color: rgba($danger-color, 0.9);
  font-family: $font-nunito;
}

/**  =====================
    26. Form CSS Ends
 =====================  **/
