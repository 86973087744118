.section-wrapper {
  padding: 32px 20px;
  background: #ffffff;
  border: 1px solid #eff1f6;
  border-radius: 12px;
  box-shadow: 0 0 15px 10px #f7f7f7;

  .hide-show-wrapper {
    .section-heading {
      font-family: Raleway;
      font-weight: 700;
      font-size: 20px;
      color: #323232;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .show-hide-text {
        font-family: Raleway;
        font-weight: 600;
        font-size: 14px;
        line-height: 16.44px;
        color: #2c4474;
      }
    }
  }

  .custom-first-column {
    font-family: Raleway;
    font-weight: 600;
    font-size: 14px;
    line-height: 140%;
    letter-spacing: 0%;
    color: #2c4474;
  }
}
