.application-listing-wrapper {
  background: #ffffff;
  border: 1px solid #eff1f6;
  border-radius: 12px;
  padding: 32px 20px;
  box-shadow: 0 0 15px 10px #f7f7f7;
  margin: 0px 16px;

  .application-heading {
    font-family: Raleway;
    font-weight: 700;
    font-size: 20px;
    line-height: 100%;
    color: #323232;
  }

  .application-tagline {
    font-family: Raleway;
    font-weight: 600;
    font-size: 14px;
    line-height: 140%;
    letter-spacing: 0%;
    color: #50535b;
  }
}

.stats-overview-container {
  border: 1px solid #eff1f6;
  background: #ffffff;
  box-shadow: 0 0 15px 10px #f7f7f7;
  border-radius: 12px;
  padding: 32px 20px;

  .stats-heading-selector {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: nowrap;
    margin: 0px;
  }

  .stats-overview-heading {
    font-family: Raleway;
    font-weight: 700;
    font-size: 20px;
    line-height: 100%;
    letter-spacing: 0%;
    color: #323232;
    text-align: left;
  }

  .stats-overview-data,
  .stats-overview-subtitle {
    color: #2c4474 !important;
    font-family: Raleway;
    font-weight: 700 !important;
    font-size: 14px;
    line-height: 100%;
    text-align: center;
    vertical-align: middle;
  }

  .stats-description {
    font-family: Raleway;
    font-weight: 600;
    font-size: 18px;
    line-height: 140%;
    letter-spacing: 0%;
    color: #50535b;
  }

  .toggle-selector-wrapper {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 10px;
    margin-top: 10px;
  }

  .toggle-label {
    font-family: Raleway;
    font-weight: 700;
    font-size: 14px;
    line-height: 100%;
    letter-spacing: 0%;
    color: #2c4474;
  }
}

.common-input .rbt-input {
  border-radius: 4px;
  padding-top: 8px;
  padding-right: 12px;
  padding-bottom: 8px;
  padding-left: 12px;
  font-weight: 600;
  border: 1px solid #9fadc9 !important;
  background: #ffffff;
  color: #2c4474 !important;
}

.common-input .rbt-input::placeholder {
  font-family: Raleway;
  font-weight: 600;
  font-size: 14px;
  line-height: 19.6px;
  color: #9fadc9 !important;
}

.common-input .form-label {
  font-family: Raleway;
  font-weight: 600;
  font-size: 14px;
  line-height: 19.6px;
  color: #50535b !important;
}

.common-input .form-control {
  border-radius: 4px;
  padding-top: 8px;
  padding-right: 12px;
  padding-bottom: 8px;
  padding-left: 12px;
  font-weight: 600;
  border: 1px solid #9fadc9 !important;
  background: #ffffff;
  color: #2c4474 !important;
}

.common-input .form-control::placeholder {
  font-family: Raleway;
  font-weight: 600;
  font-size: 14px;
  line-height: 19.6px;
  color: #9fadc9 !important;
}

.common-input .form-label {
  font-family: Raleway;
  font-weight: 600;
  font-size: 14px;
  line-height: 19.6px;
  color: #50535b !important;
}

.custom-toggle .custom-control-label::before {
  background-color: #cfd6e4 !important;
  border-color: #cfd6e4 !important;
}

.custom-toggle .custom-control-input:checked ~ .custom-control-label::before {
  background-color: #5f77a5 !important;
  border-color: #5f77a5 !important;
}

.expired-row {
  background-color: #c9d4e9 !important;
}
