$B100: #eff1f6;
$B200: #cfd6e4;
$B300: #9fadc9;
$B500: #5f77a5;
$B700: #2c4474;
$B800: #1f3665;

.mui-datepicker-label {
  color: black;
  font-family: 'raleway', serif;
  font-size: 0.8rem;
  font-weight: 600;
  line-height: 0.9rem;
}

.mui-datepicker {
  input {
    color: #2c4474;
    font-family: 'raleway', serif;
    font-size: 0.9rem;
    font-weight: 600;
    line-height: 1.6rem;
    font-feature-settings: 'lnum';
    // height: 40px;
    border-radius: 4px;
    border: 1px solid $B300;
    padding: 6px 10px;

    &:focus,
    &:hover {
      border: 1px solid $B500;
      outline: 1px solid $B500;
      box-shadow: none;
    }
  }

  button {
    right: 8px;
  }
}

.validation-errors {
  margin-top: 8px;
  font-size: 1rem;
  color: #dc3545;
}

.mui-input {
  color: #2c4474;
  font-family: 'raleway', serif;
  font-size: 0.9rem;
  font-weight: 600;
  line-height: 1.6rem;
  font-feature-settings: 'lnum';
  letter-spacing: 1px;
  // height: 40px;
  border-radius: 4px;
  border: 1px solid $B300;
  padding: 6px 10px;

  &[disabled] {
    background-color: rgba(0, 0, 0, 0.1);
    color: #2c4474;
  }

  &:focus,
  &:hover {
    border: 1px solid $B500;
    outline: 1px solid $B500;
  }
}

.react-bootstrap-table table {
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  overflow: hidden;

  td.react-bs-table-no-data {
    text-align: left;
  }
}

.react-datepicker__header {
  & .form-control {
    width: unset;
  }
}

._loading_overlay_overlay {
  background-color: rgb(255 255 255 / 65%) !important;
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(3px);
}

.rbt-input-main + .rbt-input-hint {
  color: transparent !important;
}

.rbt-token-primary {
  background-color: $primary-color;
  color: white;
}

.input-group-append {
  z-index: 0;
}

.dropdown-menu {
  min-width: 400px;
}

div[class*='custom-dropdown--Show'] {
  .dropdown-menu {
    min-width: auto;
  }
}

.w-115 {
  width: 115px;
}

.rbt-token {
  &.disable-select {
    pointer-events: none;

    button {
      pointer-events: all;
    }

    &.rbt-token-active {
      background-color: transparent;
      color: transparent;
    }
  }

  &.client-lead {
    background-color: $primary-color;
    color: white;
  }

  &.client-director {
    background-color: $primary-green-color;
    color: white;

    &.client-lead {
      background: linear-gradient(to right, $primary-color, $primary-green-color);
    }
  }

  &.rbt-token-removeable {
    cursor: default;
    padding-right: 30px;
  }
}

:root {
  font-feature-settings: 'lnum';
  -moz-font-feature-settings: 'lnum';
  -webkit-font-feature-settings: 'lnum';
}

.error-toast {
  color: #b14848;
  background-color: #fbe9e9 !important;
  font-family: Raleway !important;
  font-weight: 600;

  &.Toastify__toast--error {
    background-color: #fbe9e9 !important;
  }

  .Toastify__close-button {
    color: #b14848;
  }

  .Toastify__progress-bar {
    color: #b14848;
    background-color: #b14848 !important;
  }
}

.success-toast {
  color: #31816c;
  background-color: #f2f6f3 !important;
  font-family: Raleway !important;
  font-weight: 600;

  &.Toastify__toast--error {
    background-color: #f2f6f3 !important;
  }

  .Toastify__close-button {
    color: #31816c;
  }

  .Toastify__progress-bar {
    color: #31816c;
    background-color: #31816c !important;
  }
}

.warning-toast {
  color: #ba9500;
  background-color: #eae8e0 !important;
  font-family: Raleway !important;
  font-weight: 600;

  &.Toastify__toast--error {
    background-color: #eae8e0 !important;
  }

  .Toastify__close-button {
    color: #ba9500;
  }

  .Toastify__progress-bar {
    color: #ffcc00;
    background-color: #ba9500 !important;
  }
}

.form-group .dropdown button {
  white-space: break-spaces;
}

.help {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 1;
  color: white;
  background: #2c4475;
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50px;
  border: 2px solid white;
  font-size: 1.5em;
  box-shadow: 0 3px 6px 0 #2c447575;
  cursor: pointer;
  font-weight: bold;
  transition: background 0.3s;

  &:hover {
    background: #264b94;
  }
}

@media (min-width: 576px) {
  .modal-dialog-centered {
    display: flex;
    align-items: flex-start;
    min-height: calc(100% - 3.5rem - 50px);
    max-height: calc(100vh - 150px);
    overflow: hidden;
    top: 50px;

    .modal-content {
      max-height: calc(100vh - 100px);
      overflow-y: scroll;
      overflow-x: hidden;
    }
  }
}
