/**  =====================
    63. Box-layout CSS start
==========================  **/
[main-theme-layout='box-layout'] {
  @media (width >= 1280px) {
    .time_picker_wrapper {
      width: 48%;
    }

    .page-wrapper {
      .bookmark {
        ul {
          margin-right: -1px;
        }
      }

      .btn-group-showcase {
        .btn-radio {
          .btn-group {
            .radio {
              input[type='radio'] {
                display: none;
              }
            }
          }
        }
      }

      .media.bitcoin-graph {
        display: block;

        .top-bitcoin {
          display: inline-block;
          vertical-align: middle;
        }

        .media-body {
          margin-top: 30px;

          .bitcoin-content {
            .bitcoin-numbers {
              h6 {
                font-size: 14px;
              }
            }

            &.text-right {
              text-align: center !important;
            }
          }
        }
      }

      .alert {
        &.inverse {
          p {
            max-width: 238px;
            display: block;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
      }

      .page-body-wrapper {
        width: 1280px;
        box-shadow: 1px 3px 25px rgb(0 0 0 / 7%);

        // sidebar css
        .page-sidebar {
          display: block;
          visibility: visible;
          transition: 0.5s;

          &.open {
            display: none;
            visibility: hidden;
            transition: 0.5s;

            ~ footer {
              width: 1280px;
            }
          }
        }

        &.sidebar-icon {
          .page-sidebar {
            left: 313px;
          }
        }

        .learning-comment {
          margin-left: -14px !important;
          float: left !important;
        }

        .todo {
          .todo-list-wrapper {
            #todo-list {
              li {
                .task-responsive {
                  min-width: 1087px;
                  overflow: auto;
                }
              }
            }
          }
        }

        .browse {
          .browse-articles {
            ul {
              li {
                a {
                  span {
                    &:first-child {
                      width: 193px;
                      white-space: nowrap;
                      overflow: hidden;
                      text-overflow: ellipsis;
                      display: inline-block;
                    }
                  }
                }
              }
            }
          }
        }

        .cart {
          .qty-box {
            width: 35%;
          }
        }

        .product-filter {
          &.new-products {
            .product-box {
              .product-details {
                padding: 20px 10px;
              }
            }
          }
        }

        .current-progress {
          table {
            tbody {
              tr {
                td {
                  &:last-child {
                    width: 147px;
                  }
                }
              }
            }
          }
        }

        .server-chart {
          #latency-chart {
            width: 497px;
          }
        }

        .active-order-table {
          max-width: 443px;
          overflow: auto;

          table {
            tbody {
              tr {
                td {
                  p {
                    width: 100px;
                  }
                }
              }
            }
          }
        }

        .activity {
          .media {
            .gradient-round {
              &.gradient-line-1 {
                &::after {
                  height: 57px;
                  bottom: -64px;
                }
              }

              &.small-line {
                &::after {
                  height: 36px;
                  bottom: -43px;
                }
              }

              &.medium-line {
                &::after {
                  height: 40px;
                  bottom: -48px;
                }
              }
            }
          }
        }

        margin: 0 auto;

        footer {
          width: 1025px;
          margin: 0 auto;
          right: 0;
          padding-right: 15px;
          margin-left: 256px;
        }

        .footer-fix {
          margin-left: 567px;
        }

        .blockquote-footer {
          width: auto;
        }

        .chat-box {
          .chat-history {
            .call-content {
              display: flex;
              align-items: center;
              justify-content: center;
              min-width: 300px;

              > div {
                z-index: 8;
                background-color: rgb(255 255 255 / 75%);
                background-blend-mode: overlay;
                width: 100%;
                padding: 30px;
                left: 15px;
              }

              button {
                width: 40%;
                font-size: 14px;
                margin: 0 auto;
              }
            }

            .call-icons {
              margin-top: 20px;
              margin-bottom: 20px;

              ul {
                li {
                  border: 1px solid #717171;
                  width: 50px;
                  height: 50px;
                  padding: 8px;
                }
              }
            }

            .receiver-img {
              margin-top: 30px;
              margin-bottom: 30px;

              img {
                width: 38%;
              }
            }

            .total-time {
              h2 {
                font-size: 28px;
                color: #717171;
              }
            }
          }
        }

        canvas {
          &#myLineCharts {
            width: 100%;
          }
        }

        .chat-right-aside {
          flex: 0 0 60%;
          max-width: 60%;
          overflow: hidden;
        }

        .caller-img {
          position: absolute;
          width: 100%;
          max-width: 100%;
          left: 15px;

          img {
            opacity: 0.7;
          }
        }

        .browser-widget {
          img {
            height: 65px;
          }
        }

        .weather-widget-two {
          .bottom-whetherinfo {
            .whether-content {
              top: 39px;
            }
          }
        }

        .custom-card {
          .card-header {
            img {
              margin-top: -73px;
            }
          }

          .card-profile {
            img {
              height: 130px;
              top: -17px;
            }
          }
        }

        .select2 {
          width: 901.781px;
        }
      }

      &.horizontal_sidebar {
        .page-body-wrapper {
          .page-sidebar {
            width: 1280px;

            .sidebar-menu {
              .right-arrow {
                left: calc(270px + (1248 - 270) * ((100vw - 320px) / (1920 - 320)));
              }
            }
          }
        }
      }

      &.compact-page {
        .footer-fix {
          width: 1280px !important;
          margin: 0 auto !important;
        }

        .page-body-wrapper {
          footer {
            margin-left: 75px;
            width: 1205px;
          }

          &.sidebar-hover {
            .page-sidebar {
              width: 75px;
              transform: translate(0);

              &:hover {
                width: 255px;
              }

              &.open {
                ~ footer {
                  width: 1280px;
                  margin-left: 0;
                }
              }
            }
          }

          .footer-fix {
            margin-left: 387px;
          }
        }
      }

      .pricing-wrapper-card {
        padding: 50px 20px;
      }

      .card {
        .blog-box {
          &.blog-grid {
            &.set-min-height {
              min-height: 400px;
            }
          }
        }
      }

      .flot-chart-placeholder {
        &#donut-color-chart-morris-daily {
          min-height: 430px;
        }
      }

      .flot-chart-placeholder {
        &#donut-color-chart-morris {
          min-height: 430px;
        }
      }

      .box-col-12 {
        flex: 0 0 100%;
        max-width: 100%;
      }

      .box-col-6 {
        flex: 0 0 50%;
        max-width: 50%;
      }

      .box-col-3 {
        flex: 0 0 25%;
        max-width: 25%;
      }

      .box-col-7 {
        flex: 0 0 60%;
        max-width: 60%;
      }

      .box-col-5 {
        flex: 0 0 40%;
        max-width: 40%;
      }

      .box-col-8 {
        flex: 0 0 70%;
        max-width: 70%;
      }

      .box-col-4 {
        flex: 0 0 30%;
        max-width: 30%;
      }

      .box-col-4a {
        flex: 0 0 33.33%;
        max-width: 33.33%;
      }

      // sass dashboard start
      .sass-top-cards {
        > .col {
          flex: 0 0 33.33%;
          max-width: 33.33%;

          &:nth-child(4),
          &:nth-child(5) {
            flex: 0 0 50%;
            max-width: 50%;
          }
        }
      }

      // sass dashboard end
      .chat-box {
        .chat-right-aside {
          .chat {
            .chat-header {
              .chat-menu-icons {
                li {
                  a {
                    i {
                      font-size: 19px;
                    }
                  }
                }
              }
            }
          }

          flex: 0 0 100%;
          max-width: calc(100% - 15px);
          overflow: hidden;

          &.bitcoin-chat {
            max-width: 100%;
          }
        }

        .toogle-bar {
          display: inline-block;
          margin-right: 0 !important;
        }
      }

      .chat-menu {
        right: 0;
        border-top: 1px solid #ddd;
        opacity: 0;
        transform: translateY(-30px);
        visibility: hidden;
        top: 81px;
        position: absolute;
        z-index: 7;
        background-color: $white;
        transition: all linear 0.3s;

        .people-list {
          ul {
            &.list {
              height: 500px;
              overflow: hidden;
            }
          }
        }
      }

      .chat-menu.show {
        opacity: 1;
        visibility: visible;
        transform: translateY(0);
        transition: all linear 0.3s;
        padding-bottom: 25px;
      }

      .ct-10.total-chart {
        .ct-chart-bar {
          .ct-series {
            .ct-bar {
              stroke-width: 23px !important;
            }
          }
        }
      }

      .email-wrap {
        .email-body {
          .email-compose {
            .cke_contents {
              &.cke_reset {
                max-height: 165px;
              }
            }
          }
        }

        .row {
          .col-xl-3 {
            + .col-xl-3 {
              padding-right: 15px;
            }
          }
        }

        .email-right-aside {
          .email-body {
            border-top-right-radius: 20px;
            border-bottom-right-radius: 20px;

            .inbox {
              height: 644px;
            }
          }

          .radius-left {
            border-top-left-radius: 20px !important;
            border-bottom-left-radius: 20px !important;
          }
        }

        .email-content {
          .email-top {
            .user-emailid {
              &::after {
                right: -10px;
              }
            }
          }
        }
      }

      .todo {
        .notification-popup {
          right: 320px;
        }
      }

      .touchspin {
        padding: 0 10px;
      }

      .comingsoon {
        video {
          min-width: 67%;
          width: 67%;
        }
      }

      .auth-bg-effect {
        .second-effect {
          left: 55%;
        }
      }

      .auth-bg-video {
        video {
          min-width: 67%;
          width: 67%;
        }
      }
    }
  }
}

/**  =====================
    63. Box-layout CSS ends
==========================  **/
