/**  =====================
     02. Generic CSS Start
==========================  **/

/* ====== Padding css starts ====== */
$i: 0;

@while $i<=50 {
  .p-#{$i} {
    padding: #{$i}px;
  }

  $i: $i + 5;
}

/* ====== Padding css ends ====== */

/* ====== Padding-left css starts ====== */
$i: 0;

@while $i<=50 {
  .p-l-#{$i} {
    padding-left: #{$i}px;
  }

  $i: $i + 5;
}

/* ====== Padding-left css ends ====== */

/* ====== Padding-top css starts ====== */
$i: 0;

@while $i<=50 {
  .p-t-#{$i} {
    padding-top: #{$i}px !important;
  }

  $i: $i + 5;
}

/* ====== Padding-top css ends ====== */

/* ====== Padding-bottom css starts ====== */
$i: 0;

@while $i<=50 {
  .p-b-#{$i} {
    padding-bottom: #{$i}px !important;
  }

  $i: $i + 5;
}

/* ====== Padding-bottom css ends ====== */

/* ====== Padding-right css starts ====== */
$i: 0;

@while $i<=50 {
  .p-r-#{$i} {
    padding-right: #{$i}px;
  }

  $i: $i + 5;
}

/* ====== Padding-right css ends ====== */

/* ====== Margin css starts ====== */
$i: 0;

@while $i<=50 {
  .m-#{$i} {
    margin: #{$i}px !important;
  }

  $i: $i + 5;
}

/* ====== Margin css ends ====== */

/* ====== Margin-top css starts ====== */
$i: 0;

@while $i<=50 {
  .m-t-#{$i} {
    margin-top: #{$i}px !important;
  }

  $i: $i + 5;
}

/* ====== Margin-top css ends ====== */

/* ====== Margin-Bottom css starts ====== */
$i: 0;

@while $i<=50 {
  .m-b-#{$i} {
    margin-bottom: #{$i}px !important;
  }

  $i: $i + 5;
}

/* ====== Margin-Bottom css ends ====== */

/* ====== Margin-left css starts ====== */
$i: 0;

@while $i<=50 {
  .m-l-#{$i} {
    margin-left: #{$i}px !important;
  }

  $i: $i + 5;
}

/* ====== Margin-left css ends ====== */

/* ====== Margin-right css starts ====== */
$i: 0;

@while $i<=50 {
  .m-r-#{$i} {
    margin-right: #{$i}px;
  }

  $i: $i + 5;
}

/* ====== Margin-right css ends ====== */

/* ====== Border-radius css starts ====== */
$i: 0;

@while $i<=10 {
  .b-r-#{$i} {
    border-radius: #{$i}px !important;
  }

  $i: $i + 1;
}

/* ====== Border-radius css ends ====== */

/* ====== Font-size css starts ====== */
$i: 12;

@while $i<=100 {
  .f-#{$i} {
    font-size: #{$i}px;
  }

  $i: $i + 2;
}

/* ====== Font-size css ends ====== */

/* ====== Font-weight css starts ====== */
$i: 100, 300, 400, 600, 700, 900;

@each $val in $i {
  .f-w-#{$val} {
    font-weight: $val;
  }
}

/* ====== Font-weight css ends ====== */

/* ====== Font-style css starts ====== */
$i: normal, italic, oblique, initial, inherit;

@each $val in $i {
  .f-s-#{$val} {
    font-style: $val;
  }
}

/* ====== Font-style css ends ====== */

/* ====== Text-Decoration css starts ====== */

$i: overline, line-through, underline, dashed, blink, dotted, initial, none, solid, wavy, inherit,
  double;

@each $val in $i {
  .text-#{$val} {
    text-decoration: $val;
  }
}

/* ====== Text-Decoration css ends ====== */

/* ====== Vertical-Align css starts ====== */
$i: baseline, sub, super, top, text-top, middle, bottom, text-bottom, initial, inherit;

@each $val in $i {
  .#{$val} {
    vertical-align: $val;
  }
}

/* ====== Vertical-Align css ends ====== */

/* ====== Position css starts ====== */

$i: static, absolute, fixed, relative, initial, inherit;

@each $val in $i {
  .p-#{$val} {
    position: $val;
  }
}

/* ====== Position css ends ====== */

/* ====== Float css starts ====== */
$i: left, right, none;

@each $val in $i {
  .f-#{$val} {
    float: $val;
  }
}

/* ====== Float css ends ====== */

/* ====== Overflow css starts ====== */
$i: hidden, visible, auto;

@each $val in $i {
  .o-#{$val} {
    overflow: $val;
  }
}

/* ====== Overflow css ends ====== */

/* ====== Image-sizes css starts ====== */
$i: 10;

@while $i<=100 {
  .img-#{$i} {
    width: #{$i}px !important;
  }

  $i: $i + 10;
}

/* ====== Image-sizes css ends ====== */

/* ======= Text css starts =========== */
$text-name: primary, secondary, success, danger, info, light, dark, primary-blue, primary-green,
  secondary-blue, primary-red, warning;
$text-color: $primary-color, $secondary-color, $success-color, $danger-color, $info-color,
  $light-color, $dark-color, $primary-blue-color, $primary-green-color, $secondary-blue-color,
  $primary-red-color, $warning-color;

@each $var in $text-name {
  $i: index($text-name, $var);
  .font-#{$var} {
    color: nth($text-color, $i) !important;
  }
}

/* ======= Text css ends =========== */

/* ======= Label-color css starts  ======= */

.label {
  border-radius: 2px;
  color: $white;
  font-size: 12px;
  line-height: 1;
  margin-bottom: 0;
  text-transform: capitalize;
}

$label-name: theme, primary, secondary, success, danger, info, light, dark, primary-blue,
  primary-green, secondary-blue, primary-red, warning;
$label-color: $primary-color, $primary-color, $secondary-color, $success-color, $danger-color,
  $info-color, $light-color, $dark-color, $primary-blue-color, $primary-green-color,
  $secondary-blue-color, $primary-red-color, $warning-color;

@each $var in $label-name {
  $i: index($label-name, $var);
  .label-#{$var} {
    background-color: nth($label-color, $i);
  }
}

/* ======= Label-color css ends  ======= */

/* ======= Badge-color css starts  ======= */

$badge-name: primary, secondary, success, danger, info, light, dark, primary-blue, primary-green,
  secondary-blue, primary-red, warning;
$badge-color: $primary-color, $secondary-color, $success-color, $danger-color, $info-color,
  $light-color, $dark-color, $primary-blue-color, $primary-green-color, $secondary-blue-color,
  $primary-red-color, $warning-color;

@each $var in $badge-name {
  $i: index($badge-name, $var);
  .badge-#{$var} {
    background-color: nth($badge-color, $i);

    @if $badge-name == 'light' {
      color: $theme-body-font-color !important;
    }
  }
}

/* ======= Badge-color css end  ======= */

/* ======= Background-color css starts  ======= */

$background-name: primary, secondary, success, danger, info, light, dark, primary-blue,
  primary-green, secondary-blue, primary-red, warning;
$background-color: $primary-color, $secondary-color, $success-color, $danger-color, $info-color,
  $light-color, $dark-color, $primary-blue-color, $primary-green-color, $secondary-blue-color,
  $primary-red-color, $warning-color;

@each $var in $background-name {
  $i: index($background-name, $var);
  .bg-#{$var} {
    background-color: nth($background-color, $i) !important;
    color: $white;
  }
}

/* ======= Background-color css end  ======= */

/* ======= Button-color css starts  ======= */
// btn--color//
@each $btn-name, $btn-color in (primary, $primary-color), (secondary, $secondary-color),
  (success, $success-color), (danger, $danger-color), (info, $info-color), (light, $light-color),
  (dark, $dark-color), (primary-blue, $primary-blue-color), (primary-green, $primary-green-color),
  (secondary-blue, $secondary-blue-color), (primary-red, $primary-red-color), (save, $save-color),
  (warning, $warning-color)
{
  .btn-#{$btn-name} {
    @if $btn-name == save or primary-blue or primary-green {
      color: #fff;
    }

    background-color: $btn-color !important;
    border-color: $btn-color !important;

    &.disabled {
      background-color: $btn-color !important;
      border-color: $btn-color !important;
    }

    &:disabled {
      background-color: $btn-color !important;
      border-color: $btn-color !important;
    }

    &:hover,
    &:focus,
    &:active,
    &.active {
      @if $btn-name == save or primary-blue or primary-green {
        background-color: lighten($btn-color, 10%) !important;
        border-color: lighten($btn-color, 10%) !important;
        color: #fff;
      } @else {
        background-color: darken($btn-color, 10%) !important;
        border-color: darken($btn-color, 10%) !important;
      }
    }

    &:focus {
      box-shadow: 0 0 0 0.2rem lighten($btn-color, 30%);
    }
  }
}

/* ======= Button-color css ends  ======= */
@each $btn-name, $btn-color in (primary, $primary-color), (secondary, $secondary-color),
  (success, $success-color), (danger, $danger-color), (info, $info-color), (light, $light-color),
  (dark, $dark-color), (primary-blue, $primary-blue-color), (primary-green, $primary-green-color),
  (secondary-blue, $secondary-blue-color), (primary-red, $primary-red-color),
  (warning, $warning-color)
{
  .btn-outline-#{$btn-name}-2x {
    border-width: 2px;
    border-color: $btn-color;
    color: $btn-color;
    background-color: transparent;

    &:hover,
    &:focus,
    &:active,
    &.active {
      color: white;
      background-color: darken($btn-color, 10%) !important;
      border-color: darken($btn-color, 10%) !important;
      box-shadow: none;
    }
  }
}

// outline buttons//
@each $btn-name, $btn-color in (primary, $primary-color), (secondary, $secondary-color),
  (success, $success-color), (danger, $danger-color), (info, $info-color), (light, $light-color),
  (dark, $dark-color), (primary-blue, $primary-blue-color), (primary-green, $primary-green-color),
  (secondary-blue, $secondary-blue-color), (primary-red, $primary-red-color),
  (warning, $warning-color)
{
  .btn-outline-#{$btn-name} {
    border-color: $btn-color;
    color: $btn-color;
    background-color: transparent;

    &.disabled {
      color: $btn-color;
    }

    @if $btn-name == 'light' {
      color: $theme-body-font-color;
    }

    &:hover,
    &:focus,
    &:active,
    &.active {
      color: white;
      background-color: darken($btn-color, 10%) !important;
      border-color: darken($btn-color, 10%) !important;
    }
  }
}

//active button css //

@each $btn-name, $btn-color in (primary, $primary-color), (secondary, $secondary-color),
  (success, $success-color), (danger, $danger-color), (info, $info-color),
  (primary-blue, $primary-blue-color), (primary-green, $primary-green-color),
  (secondary-blue, $secondary-blue-color), (primary-red, $primary-red-color), (light, $light-color),
  (dark, $dark-color), (warning, $warning-color)
{
  .btn-#{$btn-name}:not([disabled]):not(.disabled).active {
    background-color: darken($btn-color, 10%);
    border-color: darken($btn-color, 10%);
    box-shadow: none !important;

    &:hover,
    &:focus,
    &:active,
    &.active {
      color: white;
      background-color: darken($btn-color, 10%);
      border-color: darken($btn-color, 10%);
    }
  }
}

//* active button css end *//

@each $btn-name, $btn-color in (primary, $primary-color), (secondary, $secondary-color),
  (success, $success-color), (danger, $danger-color), (info, $info-color), (light, $light-color),
  (dark, $dark-color), (primary-blue, $primary-blue-color), (primary-green, $primary-green-color),
  (secondary-blue, $secondary-blue-color), (primary-red, $primary-red-color),
  (warning, $warning-color)
{
  .btn-outline-#{$btn-name}-2x:not([disabled]):not(.disabled).active {
    background-color: $btn-color;
    border-color: $btn-color;
    box-shadow: none !important;
    color: white;

    &:hover,
    &:focus,
    &:active,
    &.active {
      color: white;
      box-shadow: none !important;
      background-color: darken($btn-color, 10%) !important;
      border-color: darken($btn-color, 10%) !important;
    }
  }
}

/* ======= Table-Border-Bottom-color css starts  ======= */

$border-name: primary, secondary, success, danger, info, light, dark, primary-blue, primary-green,
  secondary-blue, primary-red, warning;
$border-color: $primary-color, $secondary-color, $success-color, $danger-color, $info-color,
  $light-color, $dark-color, $primary-blue-color, $primary-green-color, $secondary-blue-color,
  $primary-red-color, $warning-color;

@each $var in $border-name {
  $i: index($border-name, $var);
  table thead .border-bottom-#{$var} th,
  table tbody .border-bottom-#{$var} th,
  table tbody .border-bottom-#{$var} td {
    border-bottom: 1px solid nth($border-color, $i);
  }
}

/* ======= Table-Border-Bottom-color css ends  ======= */

/* ======= Table styling css starts  ======= */

$table-name: primary, secondary, success, danger, info, light, dark, primary-blue, primary-green,
  secondary-blue, primary-red, warning;
$table-color: $primary-color, $secondary-color, $success-color, $danger-color, $info-color,
  $light-color, $dark-color, $primary-blue-color, $primary-green-color, $secondary-blue-color,
  $primary-red-color, $warning-color;

@each $var in $table-name {
  $i: index($table-name, $var);
  .table-styling .table-#{$var},
  .table-styling.table-#{$var} {
    background-color: nth($border-color, $i);
    color: $white;
    border: 3px solid nth($border-color, $i);

    thead {
      background-color: darken(nth($border-color, $i), 10%);
      border: 3px solid darken(nth($border-color, $i), 10%);
    }
  }
}

/* ======= Table styling css ends  ======= */

/* ======= All-Borders-color css starts  ======= */

$b-name: primary, secondary, success, danger, info, light, dark, primary-blue, primary-green,
  secondary-blue, primary-red, warning;
$b-color: $primary-color, $secondary-color, $success-color, $danger-color, $info-color, $light-color,
  $dark-color, $primary-blue-color, $primary-green-color, $secondary-blue-color, $primary-red-color,
  $warning-color;

@each $var in $b-name {
  $i: index($b-name, $var);
  .b-#{$var} {
    border: 1px solid nth($b-color, $i) !important;
  }
  .b-t-#{$var} {
    border-top: 1px solid nth($b-color, $i) !important;
  }
  .b-b-#{$var} {
    border-bottom: 1px solid nth($b-color, $i) !important;
  }
  .b-l-#{$var} {
    border-left: 1px solid nth($b-color, $i) !important;
  }
  .b-r-#{$var} {
    border-right: 1px solid nth($b-color, $i) !important;
  }
}

/* ======= All-Borders-color css ends  ======= */

/* ====== Border width css starts ====== */
$i: 1;

@while $i<=10 {
  .border-#{$i} {
    border-width: #{$i}px !important;
  }

  $i: $i + 1;
}

/* ====== Border width css ends ====== */

.opacity-0 {
  opacity: 0;
}

.shadow-0 {
  box-shadow: none;
}

/**  ====== custom scrollbar css start ======  **/
.digits {
  font-family: $font-work-sans, $font-serif;
}

.custom-scrollbar {
  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px $light-gray;
  }

  &::-webkit-scrollbar {
    width: 6px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: rgb(68 102 242 / 15%);
  }
}

/**  ====== Custom scrollbar css end ======  **/

/**  ====== Animation css Start ======  **/
.comment {
  color: #9f9ba5;
  font-style: italic;
}

.line {
  color: $dark-color;
}

.line {
  pre {
    font-size: 100%;
  }
}

/**  ====== Animation css end ======  **/

/**  ====== list style css Start ======  **/
.list-circle {
  list-style: circle;
}

/**  ====== list style css end ======  **/

/**  ====== Modal style css Start ======  **/
.theme-close {
  opacity: 1;
  height: 40px;
  width: 40px;
  position: absolute;
  z-index: 1;
  right: 0;
  background-color: $white !important;
  border-radius: 5px;
}

.applications-loading {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: $primary-green-color;

  svg {
    margin: 0 0 20px;
    max-width: 50px;
  }

  & div[role*='status'] {
    width: 150px;
    height: 150px;
    margin-bottom: 15px;
    color: $primary-green-color;
  }

  & span {
    margin: 5px 0;
    font-size: 24px;
    font-family: $font-nunito;
    color: $primary-black-color;
    text-align: center;

    &:first-of-type {
      color: $primary-blue-color;
      font-weight: 600;
    }
  }
  & button {
    margin: 10px 0;
  }
}

/**  ====== Modal style css end ======  **/

/**  ====== Animation css start ======  **/
.options {
  > div {
    color: $gray-60;
    display: inline-block;
    padding: 2px 10px;
    border: 1px solid;
    margin: 0 8px 8px 0;
    transition: all 0.3s ease;

    &:hover {
      background-color: $primary-color;
      color: $white;
      transition: all 0.3s ease;
    }
  }
}

/**  ====== Animation css Ends ======  **/

.modal-footer {
  flex-wrap: wrap;
}

.bootstrap-datetimepicker-widget {
  font-family: $font-work-sans, $font-serif;
}

.typeahead {
  .theme-form {
    .form-group {
      margin-bottom: 0;
    }
  }
}

.editor-statusbar {
  border-bottom: 1px solid rgb(0 0 0 / 12.5%);
}

.datepicker {
  z-index: 99;
}

.page-item.active {
  .page-link {
    background-color: $primary-color;
    border-color: $primary-color;
  }
}

.page-item {
  .page-link {
    color: $primary-color;
  }
}

.react-bootstrap-table-page-btns-ul {
  justify-content: center;
}

/**  =====================
     02. Generic CSS Ends
==========================  **/
