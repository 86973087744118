// General variables
$theme-font-color: #242934;
$theme-body-font-color: #1b3155;
$theme-body-sub-title-color: #6e7e96;

// Theme colors variables
$border-table: #f6f6f6;
$primary-color: #5978a9; //#158df7;
$secondary-color: #c2c699; //#fb2e63 ;
$success-color: #6ca589; //#51bb25 ;
$info-color: #0b5d37; //#544fff  ;
$save-color: #589d62; //#49B245;
$warning-color: #db5000; //#fb740d;
$danger-color: lighten(#d40000, 25%); //#ff3f70 ;
$light-color: #b3b3bd; //#e8f4fe ;
$primary-blue-color: #5f77a5;
$secondary-blue-color: #5f77aa;
$primary-green-color: #76a48e;
$primary-red-color: #ec625c;
$primary-black-color: #252933;
$primary-link-color: #1f8dff;
$toggle-green-color: #8cb8a2;
$row-expired-color: #a1a3a8;
$nearly-due-color: #5f77a5;
$custom-text-regular: #a5abbb;
$custom-text-active: #252933;
$custom-hr-color: #ced4da;
$semi-dark: #aaa;
$light-semi-gray: #efefef;
$light-gray: #e8ebf2;
$light-blue: #eff1f6;
$light-semi-blue: #cfd6e4;
$dark-gray: #898989;
$dark-neutral: #50535b;
$dark-color: #0e3663; //#2c323f;
$gray-60: #ccc;
$card-body-color: #f9f9fa;
$transparent-color: transparent;
$auth-bg-color: #fafafa;
$light: #fdfeff;
$light-text: #999;
$light-background: #f6f7fb;
$light-font: rgb(27 49 85 / 60%);
$light-semi-font: rgb(27 49 85 / 40%);
$light-chartist-grid-color: #f6f9fd;
$off-white: #ffebce;
$dark-primary: #448aff;
$pitch-color: #fe8a7d;
$light-pitch: #fecf8b;
$flower-pink: #fb6d9d;
$dark-sky: #536dfe;
$color-scarpa: #4c5667;
$color-fiord: #455a64;
$dark-blue: #00008b;
$dark-semi-blue: #2c4474;
$black: #000;
$white: #fff;
$btn-bhome-bg-c: #ff6060;
$btn-search-bg-c: #ffd042;
$night-rider: #323232;

// error page color end

// gradient colors
$gradient-primary: linear-gradient(#1693f8, #0d67e9);
$gradient-danger: linear-gradient(#f73769, #f73869);
$gradient-success: linear-gradient(#52b914, #2c9500);
$gradient-warning: linear-gradient(#fb5c00, #cd3804);
$gradient-primary-direction: linear-gradient(
  to right,
  lighten($primary-color, 10%),
  darken($primary-color, 8%)
);
$gradient-secondary-direction: linear-gradient(
  to right,
  lighten($secondary-color, 10%),
  darken($secondary-color, 5%)
);

$gradient-danger-direction: linear-gradient(to right, #ff3f70, #f73769);
$gradient-success-direction: linear-gradient(to right, #4bda6c, #3bc05b);
$gradient-info-direction: linear-gradient(to right, #a76cff, #8d41ff);
$gradient-warning-direction: linear-gradient(to right, #ee933a, #ff7336);

//error input color start
$light-body-bg-color: #fafdff;

//fonts
$font-work-sans:
  nunito,
  work sans,
  work-sans;
$font-nunito: nunito;
$font-poppins: poppins;
$font-raleway: raleway;
$font-serif: sans-serif;
$font-themify: themify;
$font-awesome: fontawesome;
$font-ICO: icofont;

//font weight
$font-weight-normal: 400;
$font-weight-medium: 500;
$font-weight-semi: 600;
$font-weight-bold: 700;

// dark layout variables
$dark-body-background: #292f3c;
$dark-card-background: #242934;
$dark-small-font-color: #98a6ad;
$dark-all-font-color: rgb(255 255 255 / 70%);
$light-all-font-color: rgb(255 255 255 / 20%);
$sidebar-submenu-font-color: rgb(255 255 255 / 70%);
$dark-border-color: $dark-body-background;
$dark-editor-document: #2b2b2b;
$bg-dark-color: #1f2533;
$dark-datatable-odd: #1f232b;
$dark-datatable-sorting: #1c212b;
$dark-datatable-sorting-even: #22262f;
$dark-even-hover-sorting: #191e27;

//General tag settings
$body-bg-color: rgb(246 246 246 / 10%);
$body-font-size: 14px;
$body-font-color: $theme-body-font-color;
$ul-padding-left: 0;
$ul-padding-right: 0;
$ancher-color: $primary-color;
$btn-focus-box-shadow: none;
$all-focus-outline-color: $primary-color;
$paragraph-font-size: 13px;
$paragraph-line-height: 1.7;
$paragraph-letter-spacing: 0.7px;
$code-tag-color: $primary-color;
$code-tag-bg-color: rgba($primary-color, 0.03);
$code-tag-padding: 3px;
$code-tag-margin: 0 3px;
$code-tag-border-radious: 2px;
$blockquote-padding: 15px;
$blockquote-border: 4px solid $light-gray;
$small-tag-padding: 10px;
$small-tag-color: $dark-color;
$pre-tag-bg-color: rgba($primary-color, 0.03);
$pre-tag-padding: 20px 0;
$list-group-active-bg-color: $primary-color;
$list-group-active-border-color: $primary-color;
$list-group-img-size: 40px;
$list-group-margin: 10px;

// Tables settings
$table-b-margin: 0;
$table-heading-color: $theme-body-font-color;
$table-row-color: $theme-body-font-color;
$table-footer-font-weight: bold;
$table-font-weight-bold: 700;
$table-font-weight-semi: 600;
$table-inverse-color: $white;
$table-hover-color: $light-background;
$table-padding: 0.75rem;
$table-xl-padding: 1.25rem 2rem;
$table-xl-font: 120%;
$table-lg-padding: 0.9rem 2rem;
$table-lg-font: 110%;
$table-de-padding: 0.75rem 2rem;
$table-de-font: 100%;
$table-sm-padding: 0.5rem 2rem;
$table-sm-font: 90%;
$table-xs-padding: 0.2rem 2rem;
$table-xs-font: 80%;
$horizontal-border-color: #f2f4ff;
$horizontal-padding: 0.75rem 2rem;
$table-inverse-bg-color: #292b2c;

//According setting
$according-card-top-margin: 10px;
$according-btn-weight: 600;
$according-btn-color: $theme-body-font-color;
$according-card-header-padding: 0.75rem 1.25rem;
$according-card-header-icon: $font-ICO;
$according-open-icon: '\eb73';
$according-close-icon: '\eb7a';

//badge settings
$badge-padding: 0.44em 0.7em;
$badge-svg-size: 10px;

//form input settings
$form-group-margin-bottom: 1.25em;
$col-form-label-font-size: 14px;
$form-control-font-size: 14px;
$form-control-border-radious: 2px;
$form-control-border-color: $light-gray;

//breadcrumb setting
$breadcrumb-ancher-color: $white;
$breadcrumb-ancher-dark-color: $black;

//buttons setting
$btn-font-size: 14px;
$btn-padding: 0.375rem 1.75rem;
$btn-lg-font-size: 18px;
$btn-sm-font-size: 12px;
$btn-xs-font-size: 11px;
$btn-xs-padding: 0.05rem 0.4rem;

//Card settings
$card-padding: 16px;
$card-margin-bottom: 16px;
$card-border-width: 0;
$card-border-color: #f2f4ff;
$card-border-radious: 20px;
$common-shadow-color: #f7f7f7;
$card-box-shadow: 0 0 15px 10px $common-shadow-color;
$card-header-font-weight: 700;
$card-header-bg-color: $white;
$card-header-font-transform: capitalize;
$card-header-span-size: 12px;
$card-header-span-color: $theme-body-sub-title-color;
$card-body-bg-color: $transparent-color;
$card-footer-bg-color: $white;

//footer settings
$footer_bg_color: $white;
$footer_top_bgr_color: $light-color;
$footer_link_align: right;
$footer_dark_color: $theme-font-color;
$footer_dark__txt_color: $white;

//form settings
$form-group-margin-bottom: 20px;
$sm-form-margin-bottom: 14px;
$form-placeholder-color: $dark-gray;
$form-placeholder-font-size: 14px;
$form-placeholder-letter-spacing: 1px;
$form-placeholder-font-weight: 100;
$form-input-border-color: $light-semi-gray;
$form-input-bg-color: $white;

//Tour settings
$tour-color: $primary-color;

//sidabr main settings
$sidebar-width: 255px;
$sidebar-position: fixed;
$sidebar-background-color: $white;
$sidebar-shadow: 0 0 11px rgb(69 110 243 / 13%);
$sidebar-overflow: auto;
$sidebar-z-index: 9;
$sidebar-transition: 0.5s;

//sidebar profile settings
$sidebar-user-section-padding: 10px;
$sidebar-user-section-shadow: 3px 2px 7px -1px rgb(68 102 242 / 13%);
$sidebar-profile-edit-icon-size: 14px;
$sidebar-profile-name-txt-color: $primary-color;
$sidebar-profile-name-txt-transfer: uppercase;
$sidebar-profile-name-txt-weight: 600;
$sidebar-profile-name-letter-specing: 1.5px;
$sidebar-profile-name-marging: 3px;
$sidebar-profile-sub-title-font-size: 10px;
$sidebar-profile-sub-title-margin: 0;
$sidebar-profile-img-shadow: 0 0 15px rgb(68 102 242 / 30%);

//Sidebar main menu setting
$sidebar-menu-padding: 15px;
$sidebar-menu-list-style: none;
$sidebar-menu-list-margin: 0;
$sidebar-icon-size: 14px;
$sidebar-icon-margin: 14px;
$sidebar-icon-stroke-width: 3px;
$sidebar-font-size: 14px;
$sidebar-letter-specing: 0.5px;
$sidebar-txt-transform: capitalize;
$sidebar-font-weight: 600;
$sidebar-font-color: $theme-body-font-color;
$sidebar-padding-top: 12px;
$sidebar-padding-bottom: 12px;
$sidebar-sub-header-padding: 15px;
$sidebar-sub-header-margin: 0;
$sidebar-heading-hover-padding: 5px;
$sidebar-hover-txt-color: $primary-color;
$sidebar-arrow-margin-top: 2px;
$sidebar-arrow-size: 15px;
$sidebar-arrow-color: $theme-body-font-color;
$sidebar-open-icon: '\f107';
$sidebar-close-icon: '\f105';
$sidebar-icon-font-family: $font-awesome;

//page settings
$page-body-padding: 0 30px; //0 15px;
$page-body-bg-color: $light-color;
$page-body-margin-bottom: 0;
$page-header-padding: 20px;
$page-header-top-padding: 08px;
$common-box-shadow: 2px 3.464px 8px 0 rgb(22 145 248 / 18%);
$page-title-font-size: 24px;
$page-title-margin-bottom: 0;
$page-title-font-weight: 600;
$page-title-text-tranform: capitalize;
$page-small-title-display: block;
$page-small-title-font-size: 12px;
$page-small-title-margin-bottom: 5px;
$page-small-title-text-transform: capitalize;
$page-small-title-text-color: $theme-body-sub-title-color;
$breadcrumb-size: 16px;
$breadcrumb-content: '/';
