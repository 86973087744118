.users-listing-wrappere {
  border: 1px solid #eff1f6;
  background: #ffffff;
  border-radius: 12px;
  padding: 32px 20px;

  .user-listing-heading {
    font-family: Raleway;
    font-weight: 700;
    font-size: 20px;
    line-height: 100%;
    letter-spacing: 0%;
    vertical-align: middle;
    color: #323232;
  }
}

.custom-user-toggle .custom-control-label::before {
  background-color: #cfd6e4 !important;
  border-color: #cfd6e4 !important;
}

.custom-user-toggle .custom-control-label::after {
  background-color: #fff !important;
}

.custom-user-toggle .custom-control-input:checked ~ .custom-control-label::before {
  background-color: #5f77a5 !important;
  border-color: #5f77a5 !important;
}

//invite user modal styles//

.inivte-user-card {
  border: 1px solid #eff1f6;
  border-radius: 8px;
  padding: 18px;
  gap: 10px;

  .invite-user-heading-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .invite-heading {
      font-family: Raleway;
      font-weight: 700;
      font-size: 18px;
      line-height: 100%;
      color: #2c4474;
    }
  }
}

.invite-modal-heading {
  font-family: Raleway;
  font-weight: 600;
  font-size: 20px;
  color: #323232;
}

.invite-modal-body {
  background-color: #ffffff !important;
}

.add-invitee-btn {
  font-family: Raleway;
  font-weight: 600;
  font-size: 14px;
  color: #2c4474;
  background: #eff1f6 !important;
  border: none !important;
}
