.auth-wrapper {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #f9fafb;
  overflow: hidden;

  .auth-container {
    width: auto;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
  }

  .auth-left {
    width: 50%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    background: #f9fafb;

    .heading-image {
      margin-top: 3rem;

      img {
        width: 100%;
        height: 100%;
      }
    }

    .contact-link {
      font-family: Raleway;
      font-weight: 600;
      font-size: 14px;
      line-height: 16.8px;
      letter-spacing: 0%;
      text-align: center;
      text-decoration: underline;
      text-decoration-style: solid;
      text-decoration-thickness: Auto;
      color: #2c4474;
      margin-bottom: 2rem;
    }

    .copyright {
      font-family: Raleway;
      font-weight: 600;
      font-size: 12px;
      line-height: 16.8px;
      letter-spacing: 0px;
      text-align: center;
      color: #2c4474;
      margin-bottom: 2rem;
    }
  }

  .auth-right {
    width: 50%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    background: #f9fafb;
    border: none;

    .auth-image {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }

  /* Adjustments for 911px */
  @media (max-width: 911px) {
    .auth-left {
      width: 50%;
    }

    .auth-right {
      width: 50%;
    }
  }
}
