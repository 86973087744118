.client-listing-wrapper {
  padding: 32px 20px;
  border-radius: 12px;
  border: 1px solid #eff1f6;
  background: #ffffff;
  box-shadow: 0 0 15px 10px #f7f7f7;

  .client-listing-heading {
    font-family: Raleway;
    font-weight: 700;
    font-size: 20px;
    color: #323232;
  }
}

.generate-memo-btn {
  border-radius: 6px;
  background-color: #eff1f6 !important;
  color: #2c4474 !important;
  border: none !important;
}

.custom-client-toggle .custom-control-label::before {
  background-color: #cfd6e4 !important;
  border-color: #cfd6e4 !important;
}

.custom-client-toggle .custom-control-label::after {
  background-color: #fff !important;
}

.custom-client-toggle .custom-control-input:checked ~ .custom-control-label::before {
  background-color: #5f77a5 !important;
  border-color: #5f77a5 !important;
}

.gen-memo-heading {
  font-family: Raleway;
  font-weight: 600;
  font-size: 20px;
  line-height: 100%;
  color: #323232;
}

.memo-modal-tagline {
  font-family: Raleway;
  font-weight: 600;
  font-size: 14px;
  color: #6e757c;
  margin-top: 16px;
}

.memo-subheading {
  font-family: Raleway;
  font-weight: 600;
  font-size: 14px;
  color: #252933;
}
