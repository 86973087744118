.file-display-container {
  display: flex;
  align-items: center;
  flex: 1;
  color: #5f77a5 !important;
  font-family: 'Helvetica', sans-serif;
  font-size: 20px;
  font-weight: 600;
  border: 2px dotted #eff1f6;
  min-height: 120px;
  border-radius: 4px;
  position: relative;
  padding: 1em;
  height: 90%;
}

.pdf-file-display-container {
  width: 100%;
  max-height: 90vh;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #f4f4f4;
  overflow-x: hidden;
  position: relative;
  padding: 20px;
  border-radius: 12px;
}

.react-pdf__Document {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.react-pdf__Page {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  background: white;
}

.react-pdf__Page__canvas {
  width: 100% !important;
  height: 100% !important;
}

.textLayer {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  pointer-events: none;
  display: none;
}

.annotationLayer {
  position: absolute;
  top: 10;
  left: 10;
  right: 0;
  bottom: 0;
  pointer-events: none;
}

.expand-icon {
  position: absolute;
  display: flex;
  align-items: center;
  color: black;
  top: 3px;
  right: 3px;
  z-index: 10;
  background: white;
  border-radius: 22%;
  padding: 4px;
}
