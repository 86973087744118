/**  =====================
    52. Datepicker CSS Start
==========================  **/

.bg-filter-date-modal {
  .react-datepicker__period-button,
  .react-datepicker__period-button:focus,
  .react-datepicker__period-button:active,
  .react-datepicker__period-button.active {
    background-color: transparent;
    border: 0;
    margin: 0;
    padding: 0;

    &:hover {
      background-color: #fecd5a !important;
      color: #000;
    }
  }

  .react-datepicker__period-button:focus,
  .react-datepicker__period-button:active,
  .react-datepicker__period-button.active {
    background-color: #fecd5a !important;
    color: rgb(0 0 0 / 75%) !important;
  }

  .react-datepicker {
    background-color: transparent;
    border: 0;

    .react-datepicker__day, .react-datepicker__day-name {
      margin: 0;
      border-radius: 0;
      font-size: 14px;
      line-height: normal;
      width: 100%;
      padding: 7px 0;
    }

    .react-datepicker__day--keyboard-selected {
      background-color: #e05d60;
    }

    .react-datepicker__day--today {
      background-color: #cfcd33;
      font-style: italic;
      font-weight: bold;
      color: #000;
    }

    .react-datepicker__day--in-range,
    .react-datepicker__day--in-selecting-range {
      background-color: #d3e3b6;
      color: #000;
    }

    .react-datepicker__day--range-start,
    .react-datepicker__day--range-end {
      background-color: #e6c731;
      font-weight: bold;
      color: #000;
    }

    .react-datepicker__day {
      transition: color 0.15s ease-in-out, background-color 0.5s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

      &:hover {
        background-color: #fde593;
      }
    }

    .react-datepicker__day-name {
      color: rgb(0 0 0 / 75%);
      font-size: 11px;
      font-weight: bold;
    }

    .react-datepicker__header {
      background-color: transparent;
      border: 0;
      padding: 0;
    }

    .react-datepicker__day--outside-month {
      color: rgb(0 0 0 / 25%);
    }

    .react-datepicker__week,
    .react-datepicker__day-names,
    .react-datepicker__navigation {
      display: flex;
      flex: 1 1 0px;
    }

    .react-datepicker__day-names,
    .react-datepicker__navigation {
      margin: 0.4rem;
      margin-bottom: 0;
    }

    .react-datepicker__month {
      margin-top: 0;
    }

    .react-datepicker__week > div:not(:last-child) {
      border-right: 1px dashed rgb(0 0 0 / 25%);
    }

    .react-datepicker__week:not(:last-child) {
      .react-datepicker__day {
        border-bottom: 1px dashed rgb(0 0 0 / 25%);
      }
    }
  }

  .periodSelectorButton {
    background-color: transparent !important;
    border: 0 !important;
    color: #000;
    font-size: 12px;
  }
}

.react-datepicker-wrapper {
  width: 100%;
}

/**  =====================
   52. Datepicker CSS End
==========================  **/


// .react-calendar{
//   width: 100% !important;
//   border: none !important;
//   border-radius: 8px;
//   padding: 15px;
//   box-shadow: 0 4px 14px rgba($primary-color ,0.15);
//   .react-calendar__tile--now {
//     border: 2px solid $primary-color;
//     background: $transparent-color;
//   }
//   .react-calendar__navigation {
//       button {
//           font-weight: 600;
//           &:hover, &:focus, &:active {
//           background: $transparent-color;
//           }
//       }
//   }
//   .react-calendar__viewContainer{
//   .react-calendar__month-view  {
//       .react-calendar__month-view__weekdays {
//       .react-calendar__month-view__weekdays__weekday {
//           abbr {
//           text-decoration: none;
//           font-weight: bold;
//           }
//       }
//       }
//   }
//   .react-calendar__tile {
//       padding: 10px;
//       border-radius: 5px;
//       &:enabled {
//       &:hover, &:focus {
//           background-color: $primary-color ;
//           color: $white;
//       }
//       }
//   }
//   .react-calendar__tile--active {
//       background-color: $primary-color ;
//   }
//   }
// }
// .react-datepicker-popper {
//   z-index: 3 !important;
// }
// .react-datepicker-wrapper {
//   display: block !important;
// }
// .date-picker {
//     .theme-form {
//         .input-group {
//             display: block !important;
//         }
//         .min-date {
//             .react-datepicker-wrapper {
//                 display: inline-block !important;
//             }
//         }
//     }
// }
// .react-datepicker {
//   border: none !important;
//   box-shadow: 0 4px 24px rgba($primary-color ,0.08);
//   .react-datepicker__time-container {
//       border-left: 1px solid #efefef;
//       .react-datepicker__time {
//           .react-datepicker__time-box {
//               ul {
//                   &.react-datepicker__time-list {
//                       li {
//                           &.react-datepicker__time-list-item--selected {
//                               background-color: $primary-color  !important;
//                           }
//                       }
//                   }
//               }
//           }
//       }
//   }
//   .react-datepicker__today-button {
//       background-color: $white !important;
//       border-top: 1px solid #efefef;
//   }
//   .react-datepicker__triangle {
//       display: none;
//   }
//   .react-datepicker__header {
//       background-color: $transparent-color !important;
//       border-bottom: 1px solid #efefef;
//   }
//   .react-datepicker__day-name {
//       font-weight: 600;
//   }
//   .react-datepicker__current-month {
//       margin-bottom: 10px;
//   }
//   .react-datepicker__current-month, .react-datepicker-time__header, .react-datepicker-year-header {
//       color: $primary-color  !important;
//   }
//   .react-datepicker__day-name, .react-datepicker__day, .react-datepicker__time-name {
//       width: 30px !important;
//       line-height: 30px !important;
//   }
//   .react-datepicker__navigation--next {
//       border-left-color: $primary-color  !important;
//   }
//   .react-datepicker__navigation--previous {
//       border-right-color: $primary-color  !important;
//   }
//   .react-datepicker__day--selected, .react-datepicker__day--in-selecting-range, .react-datepicker__day--in-range, .react-datepicker__month-text--selected, .react-datepicker__month-text--in-selecting-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--selected, .react-datepicker__quarter-text--in-selecting-range, .react-datepicker__quarter-text--in-range {
//       background-color: $primary-color  !important;
//       &:hover {
//           background-color: $primary-color  !important;
//       }
//   }
//   .react-datepicker__day--in-selecting-range, .react-datepicker__month-text--in-selecting-range, .react-datepicker__quarter-text--in-selecting-range {
//       background-color: rgba($primary-color ,0.15);
//   }
//   .react-datepicker__month--selecting-range {
//       .react-datepicker__day--in-range, .react-datepicker__month-text--in-range {
//           color: $white !important;
//       }
//   }
//   .react-datepicker__quarter-text--in-range {
//       color: $white !important;
//   }
//   .react-datepicker__day--today, .react-datepicker__day--selected {
//       background-color: $primary-color  !important;
//       color: $white !important;
//       border-radius: 0.3rem;
//   }
// }
// // timepicker

// .classic_theme_container {
//     height: 144px !important;
// }
// .react_times_button {
//     .wrapper {
//         width: 100%;
//     }
// }
// .time_picker_wrapper2 {
//     display: flex;
//     width: auto !important;
//     .time_picker_container {
//         width: 300px;
//     }
//     .time_picker_modal_container {
//         top: unset !important;
//         bottom: 82px !important;
//     }
// }
// .time_picker_wrapper, .time_picker_wrapper2 {
//     width: 300px;
//     .time_picker_container {
//         .time_picker_modal_header, .time_picker_modal_footer, .timezone_picker_modal_header {
//             background-color: $primary-color ;
//         }
//         .picker_pointer {
//             background-color: $primary-color ;
//             .pointer_drag {
//                 background-color: $primary-color ;
//             }
//         }
//         .picker_center {
//             background-color: $primary-color ;
//         }
//         .classic_theme_container {
//             .classic_time {
//                 &.light {
//                     &.active, &:hover {
//                         background-color: $primary-color ;
//                     }
//                 }
//             }
//         }
//     }
//     .gap {
//         padding: 10px;
//     }
// }
// .time_picker_modal_container {
//     width: unset !important;
// }
// // responsive
// @media only screen and (max-width: 1660px) {
//     .date-range {
//         display: flex;
//     }
// }
// @media only screen and (max-width: 1107px) {
//   .date-range {
//     display: unset;
//       .react-datepicker-wrapper {
//           &:last-child {
//               margin-top: 15px;
//           }
//       }
//   }
// }
// @media only screen and (max-width: 575px) {
//   .react-datepicker {
//       .react-datepicker__day-name, .react-datepicker__day, .react-datepicker__time-name {
//           width: 25px !important;
//           line-height: 25px !important;
//       }
//   }
//   .date-picker {
//       .theme-form {
//           .form-row {
//               .form-row {
//                   .col-form-label {
//                       padding-left: 0;
//                   }
//               }
//           }
//       }
//   }
// }
// @media only screen and (max-width: 475px) {
//   .date-range {
//       .react-datepicker-wrapper {
//           &:last-child {
//               margin-top: 15px;
//           }
//       }
//   }
// }

// @media only screen and (max-width: 1800px) {
//     // .react-datepicker-popper {
//     //     //transform: translate3d(-64px, 64px, 0px) !important;
//     // }
// }
