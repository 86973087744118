/* Modal */
.awards-modal {
  h5 {
    font-family: $font-raleway !important;
    font-weight: $font-weight-semi;
    color: $night-rider !important;
    margin-bottom: 25px;
  }
}

/* Project view */
.details-card-header {
  border-bottom: none !important;
}

/* Modals */
.award-notification-modal .modal-dialog {
  max-width: 900px;
}

.award-payment-modal .modal-dialog {
  max-width: 700px;
}
