/* Custom styles for the date picker */
.date-picker-button {
  border: 1px solid #2c4474 !important;
  background-color: white !important;
  color: #2c4474 !important;
  font-size: 14px !important;
  font-weight: 600 !important;
  min-width: 100px !important;
  min-height: 40px !important;
  max-height: 40px !important;
  overflow: hidden !important;
  border-radius: 0px !important;
  padding: 8px;
}

.date-picker-button:hover {
  background-color: white !important;
  color: #2c4474 !important;
}
.date-picker-button.has-value {
  background-color: white !important;
  color: #2c4474 !important;
  border: 1px solid #2c4474 !important;
}

.periodSelectorButton:hover {
  background-color: #f0f0f0 !important;
  color: #000 !important;
}

.periodSelectorButton:active,
.periodSelectorButton:focus {
  background-color: #5f77a5 !important;
  color: #fff !important;
}

.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range {
  background-color: #2c4474 !important;
  border: 1px solid #eff1f6;
  color: #fff !important;
}

.react-datepicker__day:hover {
  background-color: #eff1f6 !important;
  color: #2c4474 !important;
}
