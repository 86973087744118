/**  =====================
     01. General CSS Start
==========================  **/
//Custom css Start
body {
  background-color: $body-bg-color;
  font-size: $body-font-size;
  overflow-x: hidden;
  font-family: $font-work-sans, $font-serif;
  color: $theme-font-color;
  min-width: 900px;
}

ul {
  padding-left: $ul-padding-left;
  list-style-type: none;
  margin-bottom: 0;
  padding-right: $ul-padding-right;
}

* a {
  color: $primary-color;
  font-size: 14px;

  &.btn-primary {
    color: $white;
  }
}

*.btn:focus {
  box-shadow: $btn-focus-box-shadow !important;
}

p {
  font-size: $paragraph-font-size;
  line-height: $paragraph-line-height;
  letter-spacing: $paragraph-letter-spacing;
}

code {
  color: $code-tag-color !important;
  background-color: $code-tag-bg-color;
  padding: $code-tag-padding;
  margin: $code-tag-margin;
  border-radius: $code-tag-border-radious;
}

blockquote {
  border-left: $blockquote-border;
  padding: $blockquote-padding;
}

blockquote {
  &.text-center {
    border: none;
    padding: $blockquote-padding;
  }
}

blockquote {
  &.text-right {
    border-left: none;
    border-right: $blockquote-border;
    padding: $blockquote-padding;
  }
}

:focus {
  outline-color: $all-focus-outline-color;
}

.disabled {
  cursor: not-allowed;
  opacity: 0.5;
}

svg {
  vertical-align: baseline;
}

input {
  &:focus {
    outline-color: $transparent-color;
  }
}

.media-widgets {
  .media-body {
    margin-bottom: 30px;
  }
}

//Custom css End

//Typography css start

.typography {
  h1,
  .h1,
  h2,
  .h2,
  h3,
  .h3,
  h4,
  .h4,
  h5,
  .h5,
  h6,
  .h6 {
    margin: 20px 0;
    margin-top: 0;

    &:last-child {
      margin-bottom: 0;
    }
  }

  small {
    padding-left: $small-tag-padding;
    color: $small-tag-color;
  }
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  line-height: 1.2;
}

.h1 {
  font-size: 2.5rem;
}

.h2 {
  font-size: 2rem;
}

.h3 {
  font-size: 1.75rem;
}

.h4 {
  font-size: 1.5rem;
}

.h5 {
  font-size: 1.25rem;
}

.h6 {
  font-size: 1rem;
}

pre {
  background-color: $pre-tag-bg-color;
  padding: $pre-tag-padding;
}

a {
  &:hover {
    text-decoration: none;
    color: $primary-color;
  }
}

//Typography css end

// Normal bootstrap css
.rounded-small {
  border-radius: 35%;
}

.list-group-item {
  position: relative;
  display: block;
  padding: 0.75rem 1.25rem;
  margin-bottom: -1px;

  // background-color: #fff;
  border: 1px solid rgb(0 0 0 / 12.5%);
}

.scroll-demo {
  padding: 10px;
  position: relative;
  border: 1px solid #e8f4fe;
  overflow: auto;
  height: 300px;
}

.irs {
  height: 50px;
}

.ace-editor {
  height: 400px;
  width: 100%;
}

.ace-monokai {
  background-color: #272822;
  color: #f8f8f2;
}

.h-100-d-center {
  min-height: 100vh;
  display: flex;
  align-items: center;
}

// ck editor
.cke_top {
  border-bottom: 1px solid $common-shadow-color !important;
  background: #fefefe !important;
}

.cke_chrome {
  border: 1px solid $common-shadow-color !important;
  border-bottom: none !important;
}

.cke_bottom {
  border-top: 1px solid $common-shadow-color !important;
  background: #fefefe !important;
  border-bottom: 1px solid $common-shadow-color !important;
}

// contact
.contact-profile-form {
  padding-bottom: 30px;
}

.contact-search {
  .form-group {
    width: 100%;

    .form-control {
      height: 35px;
    }

    &::before {
      left: 67px !important;
      top: 5px !important;
    }

    &::after {
      left: 37px !important;
      top: 8px !important;
    }
  }
}

.contact-filter {
  display: flex;
  align-items: center;

  .input-range,
  h5 {
    margin-top: -15px;
  }

  h5 {
    width: 224px;
  }
}

.contact-table {
  table {
    th,
    td {
      border-top: none;
      padding: 30px;
      vertical-align: middle;
    }

    tr {
      td {
        border-bottom: 1px solid $light-semi-gray;

        h6 {
          margin-bottom: 0;
        }

        .btn-primary {
          color: $white;

          &:hover {
            color: $white;
          }
        }
      }

      &:last-child {
        td {
          border-bottom: none;
        }
      }
    }
  }
}

.change-img-button {
  margin: 0 auto;
}

.contact-profile {
  position: relative;

  .icon-wrapper {
    position: absolute;
    top: 73%;
    left: 53%;
    padding: 10px;
    background-color: #fff;
    border-radius: 25px;
    cursor: pointer;
  }
}

.upload {
  position: absolute;
  width: 36px;
  left: 0;
  right: 0;
  opacity: 0;
}

.search-not-found {
  align-items: center;
  justify-content: center;
  height: 631px;
  display: flex;

  .second-search {
    width: 200px;
  }
}

// slider css
.carousel-indicators {
  z-index: 8;
}

.tag-badge-outer {
  margin-right: 5px;
}

.badge-extra-small {
  padding: 5px !important;
  font-size: 1.2em;
}

.react-autosuggest__container {
  display: inline-block;
}

.react-autosuggest__suggestions-container--open {
  position: absolute;
  border: 1px solid #cacaca;
  background: #eee;
  border-radius: 10px;
  margin-top: 5px;
  padding: 10px 0;
}

.react-autosuggest__suggestions-container--open ul li {
  padding: 5px 15px;
}

.react-autosuggest__suggestions-container--open ul li span {
}

.tagarea-area {
  cursor: text !important;
}

.tagarea-input {
  border: 0;
}

// Styles for segmented controls.

.application-sections {
  display: flex;
  max-width: 360px;
  width: 100%;
  position: relative;
  user-select: none;
  -webkit-tap-highlight-color: rgb(0 0 0 / 0%);
  z-index: 1;
  margin: 0;
  font-size: 14px;
  border: 2px solid $primary-blue-color;
  border-radius: 3px;
  color: $primary-color;
  background-color: #fff;

  @media screen and (width >=768px) {
    margin-bottom: 16px;
  }
}

.application-sections .checked {
  color: white;
  background-color: $primary-color;
}

.application-sections div {
  cursor: pointer;
  background-color: white;
  color: $primary-color;
  width: 50%;
  padding: 6px 0;
  font-weight: bold;
  text-align: center;
}

/* Bootstrap css extension */
.col-2dot4,
.col-sm-2dot4,
.col-md-2dot4,
.col-lg-2dot4,
.col-xl-2dot4 {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
}

.col-2dot4 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 20%;
  flex: 0 0 20%;
  max-width: 20%;
}

@media (width >=540px) {
  .col-sm-2dot4 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 20%;
    flex: 0 0 20%;
    max-width: 20%;
  }
}

@media (width >=720px) {
  .col-md-2dot4 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 20%;
    flex: 0 0 20%;
    max-width: 20%;
  }
}

@media (width >=960px) {
  .col-lg-2dot4 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 20%;
    flex: 0 0 20%;
    max-width: 20%;
  }
}

@media (width >=1140px) {
  .col-xl-2dot4 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 20%;
    flex: 0 0 20%;
    max-width: 20%;
  }
}

.w-90 {
  width: 90%;
}

.periodSelectorButton {
  padding: 0.375rem 0.75rem !important;
}

.btn-status0,
.btn-status1,
.btn-status2,
.btn-status3,
.btn-status4,
.btn-status5,
.btn-status6,
.btn-status7,
.btn-status8,
.btn-status9 {
  transition:
    color 0.15s ease-in-out,
    background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
}

.btn-status0,
.badge-status0 {
  color: #dfe1e5 !important;
  border: 1px solid #dfe1e5 !important;
}

.btn-status0:hover {
  background: #eff0f2 !important;
}

.btn-status1,
.badge-status1 {
  color: #87c3f1 !important;
  border: 1px solid #87c3f1 !important;
}

.btn-status1:hover {
  background: #bcdef8 !important;
}

.btn-status2,
.badge-status2 {
  color: #f6e077 !important;
  border: 1px solid #f6e077 !important;
}

.btn-status2:hover {
  background: #fbeeb3 !important;
}

.btn-status3,
.badge-status3 {
  color: #daa0dd !important;
  border: 1px solid #daa0dd !important;
}

.btn-status3:hover {
  background: #edcaee !important;
}

.btn-status4,
.badge-status4 {
  color: #98dabf !important;
  border: 1px solid #98dabf !important;
}

.btn-status4:hover {
  background: #c5eddd !important;
}

.btn-status5,
.badge-status5 {
  color: #f0abae !important;
  border: 1px solid #f0abae !important;
}

.btn-status5:hover {
  background: #f8d0d2 !important;
}

.btn-status6,
.badge-status6 {
  color: #fcd5b1 !important;
  border: 1px solid #fcd5b1 !important;
}

.btn-status6:hover {
  background: #eff0f2 !important;
}

.btn-status7,
.badge-status7 {
  color: #fcd5b1 !important;
  border: 1px solid #fcd5b1 !important;
}

.btn-status7:hover {
  background: #eff0f2 !important;
}

.btn-status8,
.badge-status8 {
  color: #fcd5b1 !important;
  border: 1px solid #fcd5b1 !important;
}

.btn-status8:hover {
  background: #e6f1fb !important;
}

.btn-status9,
.badge-status9 {
  color: #fcd5b1 !important;
  border: 1px solid #fcd5b1 !important;
}

.btn-status9:hover {
  background: #fee9d4 !important;
}

#ads {
  margin: 30px 0;
}

#ads .card.rounded {
  border-radius: 5px;
  padding: 30px 0 10px;
}

#ads .card-notify-badge {
  position: absolute;
  left: -10px;
  top: -20px;
  background: #f2d900;
  text-align: center;
  border-radius: 30px;
  color: #000;
  padding: 5px 10px;
  font-size: 14px;
}

#ads .card-notify-year {
  position: absolute;
  right: -10px;
  top: -20px;
  background: #f44;
  border-radius: 50%;
  text-align: center;
  color: #fff;
  font-size: 14px;
  width: 30px;
  height: 30px;
  padding: 4px 0 0;
}

#ads .card-detail-badge {
  background: #f2d900;
  text-align: center;
  border-radius: 30px;
  color: #000;
  padding: 5px 10px;
  font-size: 14px;
}

#ads .card:hover {
  background: #fff;
  box-shadow: 12px 15px 20px 0 rgb(46 61 73 / 15%);
  border-radius: 4px;
  transition: all 0.3s ease;
}

#ads .card-image-overlay {
  font-size: 20px;
}

#ads .card-image-overlay span {
  display: inline-block;
}

#ads .ad-btn {
  text-transform: uppercase;
  width: 150px;
  height: 40px;
  border-radius: 80px;
  font-size: 16px;
  line-height: 35px;
  text-align: center;
  border: 3px solid #e6de08;
  display: block;
  text-decoration: none;
  margin: 20px auto 1px;
  color: #000;
  overflow: hidden;
  position: relative;
  background-color: #e6de08;
}

#ads .ad-btn:hover {
  background-color: #e6de08;
  color: #1e1717;
  border: 2px solid #e6de08;
  background: transparent;
  transition: all 0.3s ease;
  box-shadow: 12px 15px 20px 0 rgb(46 61 73 / 15%);
}

#ads .ad-title h5 {
  text-transform: uppercase;
  font-size: 18px;
}

.urgent-row {
  background-color: lighten($nearly-due-color, 20%) !important; //#edf5ff !important;

  .due-date-col {
    font-weight: bold;
    color: $white;
  }

  button {
    background-color: #496089 !important;

    &:hover {
      background-color: #415b8b !important;
    }
  }

  // &:hover {
  //     background-color: #dcebff !important;
  // }
}

.urgent-row-start {
  background-color: lighten($nearly-due-color, 20%) !important; //#edf5ff !important;

  button {
    background-color: #496089 !important;

    &:hover {
      background-color: #415b8b !important;
    }
  }

  .start-date-col {
    font-weight: bold;
    color: $white;
  }
}

.expired-row {
  background-color: lighten($row-expired-color, 20%) !important;

  td {
    opacity: 0.5;
  }

  .status-col,
  .actions-col {
    opacity: 1;
  }

  .due-date-col {
    font-weight: bold;
    color: #f00;
  }

  // &:hover {
  //     background-color: #ff9a98 !important;
  // }
}

.unread-program {
  height: 10px;
  width: 10px;
  background-color: #f00;
  border-radius: 50%;
  margin-bottom: 4px;
}

.btn-outline-select {
  background-color: #fff;
  border: 1px solid #ced4da;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 0.9rem !important;
  padding: 0.375rem 0.75rem !important;
}

.state-abbreviation {
  color: #8594a3;
  padding: 0 5px;
}

.validation-errors {
  display: block;
  width: 100%;
  margin-top: 8px;
  font-size: 1rem;
  color: #dc3545;
}

.form-cards-container {
  height: 500px;
  background-color: #f7f7f7;
  padding: 10px 15px;
}

.rbt-input-multi {
  padding-right: 35px;
}

.custom-control-input:focus ~ .custom-control-label::before {
  border-color: $toggle-green-color !important;
  box-shadow: 0 0 0 0.2rem rgb(89 120 169 / 25%) !important;
}

.custom-control-input:checked ~ .custom-control-label::before {
  border-color: $toggle-green-color !important;
  background-color: $toggle-green-color !important;
}

.custom-control-input:active ~ .custom-control-label::before {
  background-color: $toggle-green-color !important;
  border-color: $toggle-green-color !important;
}

.custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
  border-color: $toggle-green-color !important;
}

.custom-control-input-green:not(:disabled):active ~ .custom-control-label::before {
  background-color: $toggle-green-color !important;
  border-color: $toggle-green-color !important;
}

.nav-link {
  font-size: 12px;
  font-family: Raleway;
  font-weight: 600;
  text-transform: uppercase;
  color: #777;

  svg {
    margin-bottom: 5px;
  }
}

.date-field-popper-container {
  z-index: 10;
}

.grants-bradcrumbs {
  width: 250px;
}

.pagination-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 50px;

  .records-showing-text {
    font-family: Raleway;
    font-weight: 600;
    font-size: 14px;
    color: #50535b;
  }

  .pagination-controls-wrapper {
    display: flex;
    align-items: center;
    gap: 20px;

    .pagination-form-field {
      display: flex;
      align-items: center;
      gap: 5px;

      .label-text {
        font-family: Raleway;
        font-weight: 600;
        font-size: 14px;
        color: #50535b;
      }

      .goToPage-input {
        width: 36px;
        height: 36px;
        text-align: center;
        color: #2c4474;
        font-size: 14px;
        font-weight: 600;
        border: 1px solid #5f77a5;

        &::-webkit-outer-spin-button,
        &::-webkit-inner-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }

        -moz-appearance: textfield;
        appearance: textfield;
      }
    }
  }
}

.pagination.react-bootstrap-table-page-btns-ul {
  border: none !important;

  .page-item {
    border: none !important;
    margin: 0 2px;

    .page-link {
      border: none !important;
      background-color: transparent;
      color: #2c4474;
      font-size: 14px;
      font-weight: 600;
    }

    &.active .page-link {
      background-color: #2c4474;
      color: white;
    }

    &:hover .page-link {
      background-color: #e9ecef;
    }

    &.disabled .page-link {
      color: #6c757d;
    }
  }
}

/**  =====================
      01. General CSS Ends
==========================  **/
