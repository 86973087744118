/**  =====================
     39. Table  CSS Start
==========================  **/
.table-bordered {
  td,
  th {
    border-color: $border-table !important;
  }
}

.pills-component {
  width: 20%;
}

.product-page-width {
  width: 80%;
}

.table {
  margin-bottom: $table-b-margin;

  th,
  td {
    padding: $table-padding;
  }

  th {
    color: $table-heading-color;
  }

  td {
    color: $table-row-color;
  }

  [class*='bg-'] {
    color: $white;
  }

  .bg-light {
    color: $theme-body-font-color;
  }

  tfoot {
    font-weight: $table-footer-font-weight;
  }

  .Double {
    th,
    td {
      border-bottom-style: double;
      border-color: $light-gray;
    }

    &:last-child {
      th,
      td {
        border-bottom: none;
      }
    }
  }

  thead {
    .Double {
      th {
        border-top: 1px double $light-gray;
        border-bottom-style: double !important;
        border-color: $light-gray !important;
      }
    }
  }

  .Dotted {
    th,
    td {
      border-bottom-style: dotted;
      border-color: $light-gray;
    }

    &:last-child {
      th,
      td {
        border-bottom: none;
      }
    }
  }

  thead {
    .Dotted {
      th {
        border-top: 1px dotted $light-gray;
        border-bottom-style: dotted !important;
        border-color: $light-gray !important;
      }
    }
  }

  .Dashed {
    th,
    td {
      border-bottom-style: dashed;
      border-color: $light-gray;
    }

    &:last-child {
      th,
      td {
        border-bottom: none;
      }
    }
  }

  thead {
    .Dashed {
      th {
        border-top: 1px dashed $light-gray;
        border-bottom-style: dashed !important;
        border-color: $light-gray !important;
      }
    }
  }
}

.table-inverse {
  th,
  td {
    color: $white;
  }
}

.table[class*='bg-'] {
  th,
  td {
    color: $white;
  }
}

.table-hover {
  tbody {
    tr:hover {
      background-color: $table-hover-color;
    }
  }
}

//Sizing class
.table-xl {
  td,
  th {
    padding: $table-xl-padding;
    font-size: $table-xl-font;
  }
}

.table-lg {
  td,
  th {
    padding: $table-lg-padding;
    font-size: $table-lg-font;
  }
}

.table-de {
  td,
  th {
    padding: $table-de-padding;
  }
}

.table-sm {
  th,
  td {
    padding: $table-sm-padding;
    font-size: $table-sm-font;
  }
}

.table-xs {
  th,
  td {
    padding: $table-xs-padding;
    font-size: $table-xs-font;
  }
}

//Table Border
.table-border-horizontal {
  tr,
  th,
  td {
    border-top: 1px solid $horizontal-border-color;
    padding: $horizontal-padding;
  }
}

.table-border-vertical {
  tr,
  th,
  td {
    border-top: 0;
    border-right: 1px solid $horizontal-border-color;
  }

  tr {
    td {
      &:last-child {
        border-right: none;
      }
    }
  }
}

.table-bordernone {
  td {
    border: 0;
  }

  .bd-t-none {
    border-top: none;
  }

  .u-s-tb {
    padding: 11px;
  }
}

.table-inverse {
  color: $white;
  background-color: $table-inverse-bg-color;

  th,
  td {
    border-color: $white;
  }

  &.table-bordered {
    border: 0;
  }
}

.table-striped {
  tbody {
    tr:nth-of-type(odd):not(:hover) {
      background-color: rgb(245 247 250 / 50%);
    }
  }
}

.table-bordered {
  td,
  th {
    border-color: $light;
  }
}

.table-borderless.hover {
  tbody:hover {
    cursor: pointer;
  }
}

.table-borderless.rows-centered {
  td {
    vertical-align: middle;
  }
}

.table-borderless {
  .loading {
    filter: blur(2px);
    pointer-events: none;
  }

  thead {
    background-color: $light-blue;
  }

  thead th {
    border-bottom: 1px solid $light-semi-blue !important;
    color: $primary-black-color;
    font-weight: $table-font-weight-bold;
    vertical-align: middle;
  }

  tr:hover {
    background-color: rgb(245 247 250);
  }

  td {
    vertical-align: middle;
    border-bottom: 1px solid $light-blue;
    color: $dark-neutral;
    font-weight: $table-font-weight-semi;
  }

  .no-row-cell-padding tr td {
    padding: 0;
  }

  tr:hover {
    .table-hover-btn {
      visibility: visible !important;
    }
  }

  tr.danger {
    td {
      color: #b14848;
    }
  }

  tr.green {
    td {
      background-color: rgb(150 233 156 / 44%);
    }
  }

  tr.yellow {
    td {
      background-color: rgba(227, 165, 19, 0.15);
    }
  }

  tr.red {
    td {
      background-color: rgb(230 159 159 / 49%);
    }
  }

  .expand-row {
    background-color: #f9fafb;
    cursor: initial;
    tbody:hover {
      cursor: initial;
    }
    tr:hover {
      background-color: initial;
    }
    td {
      border-bottom: 1px solid #f7f6f6;
    }
  }

  .expand-cell-header svg {
    position: relative;
    top: 7px;
  }
}

.react-bootstrap-table table {
  table-layout: auto;
}

.table-row-selected {
  background-color: #9dc39a !important;

  td {
    color: $theme-body-font-color;
  }
  td.due-date-col {
    color: white;
  }

  td button {
    background: $info-color;
  }
}

.table-responsive.borderless {
  table.table {
    tbody {
      tr {
        td {
          border: none !important;
        }
      }
    }
  }
}

// Mobile responsiveness of the table.
@media only screen and (width <= 800px) {
  /* Force table to not be like tables anymore */
  .react-bootstrap-table table,
  .react-bootstrap-table thead,
  .react-bootstrap-table tbody,
  .react-bootstrap-table th,
  .react-bootstrap-table td,
  .react-bootstrap-table tr {
    display: block;
  }

  /* Hide table headers (but not display: none;, for accessibility) */
  .react-bootstrap-table thead tr {
    position: absolute;
    top: -9999px;
    left: -9999px;
  }

  .react-bootstrap-table tr {
    border: 1px solid #ccc;
  }

  .react-bootstrap-table td {
    /* Behave  like a "row" */
    border: none;
    border-bottom: 1px solid #eee;
    position: relative;
    padding-left: 5%;
    white-space: normal;
    text-align: left;
  }

  .react-bootstrap-table td::before {
    /* Now like a table header */
    position: absolute;

    /* Top/left values mimic padding */
    top: 6px;
    left: 6px;
    width: 45%;
    padding-right: 10px;
    white-space: nowrap;
    text-align: left;
    font-weight: bold;
  }

  .react-bootstrap-table td::before {
    content: attr(data-title);
  }
}

.application-content {
  &__list {
    &--dissabled {
      td[class*='selection-cell'] {
        display: none;
      }

      & th:first-of-type {
        display: none;
      }
    }

    &--active {
      display: flex;
    }

    div[data-testid*='wrapper'] {
      flex: 1 1 auto;
    }
  }
}

/**  =====================
     39.Table CSS Ends
==========================  **/
