$header-size: 130px;

/**  =====================
    05. Header CSS Start
==========================  **/
@keyframes pagebody {
  0% {
    opacity: 0;
    transform: scale3d(0.95, 0.95, 0.95) translate(-20px, -100px);
  }

  50% {
    opacity: 1;
  }
}

@keyframes zoomOut {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0;
    transform: scale3d(0.3, 0.3, 0.3);
  }

  100% {
    opacity: 0;
  }
}

@keyframes slideInLeft {
  0% {
    transform: translate3d(-100%, 0, 0);
    visibility: visible;
  }

  100% {
    transform: translateZ(0);
  }
}

@keyframes fadeInDown {
  0% {
    opacity: 0;
    transform: translate3d(0, -100%, 0);
  }

  100% {
    opacity: 1;
    transform: none;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
%common {
  border-left-color: $dark-color;
  transition: $sidebar-transition;
}
%for-animated-hover {
  box-shadow: 0 0 2px 2px $light-color;
  transform: translateY(30px);
  opacity: 0;
  visibility: hidden;
  left: 0;
}
%for-animated-hover-box {
  opacity: 1;
  transform: translateY(0);
  visibility: visible;

  &::before {
    width: 0;
    height: 0;
    border-left: 7px solid transparent;
    border-right: 7px solid transparent;
    border-bottom: 7px solid $white;
    content: '';
    top: -7px;
    position: absolute;
    left: 10px;
    z-index: 2;
  }

  &::after {
    width: 0;
    height: 0;
    border-left: 7px solid transparent;
    border-right: 7px solid transparent;
    border-bottom: 7px solid $dark-gray;
    content: '';
    top: -7px;
    position: absolute;
    left: 10px;
    z-index: 1;
  }
}
%sidebar-icon {
  display: block;
  transform: rotateX(0deg);
  z-index: 1;
  background-color: $white;
  color: $dark-gray;
  font-size: 14px;
  opacity: 1;
  margin-top: -70px;
  padding: 10px 0;
  text-align: left;
  box-shadow: 0 0 10px rgb(0 0 0 / 13%);
  position: absolute;
  width: 245px;
  margin-left: 115px;
}

/* ======= Page Header css Start ======= */
.nav-menus-left {
  li {
    .search-icon {
      border-radius: 100%;
      background-image: $gradient-primary;
      width: 36px;
      height: 35px;
      display: flex;
      align-items: center;
      justify-content: center;
      box-shadow: $common-box-shadow;
      cursor: pointer;
      position: relative;

      i {
        color: $white;
      }
    }
  }
}

.compactLogo {
  a {
    img {
      &:first-child {
        display: none;
      }
    }
  }

  &.show {
    a {
      img {
        &:first-child {
          display: block;
          margin: 0 auto;
        }

        &:last-child {
          display: none;
        }
      }
    }
  }
}

.page-wrapper {
  .page-body-wrapper {
    .page-body {
      min-height: calc(100vh - #{$header-size});
      margin-top: $header-size;
      padding: $page-body-padding;
      position: relative;
      background-color: $light;

      > div {
        > div {
          &.zoomout-enter-done {
            animation: zoomOut 1000ms ease-in-out;
          }

          &.slidefade-enter-done {
            animation: slideInLeft 1000ms ease-in-out;
          }

          &.fade-enter-done {
            animation: fadeIn 1000ms ease-in-out;
          }

          &.fadebottom-enter-done {
            animation: fadeInDown 1000ms ease-in-out;
          }

          &.slidefade-enter,
          &.slidefade-exit,
          &.fade-enter,
          &.fade-exit,
          &.zoomout-enter,
          &.zoomout-exit,
          &.fadebottom-enter,
          &.fadebottom-exit,
          &.none-enter-done,
          &.none-enter,
          &.none-exit {
            animation: unset;
          }
        }
      }
    }

    .page-header {
      padding-top: $page-header-top-padding;
      padding-bottom: $page-header-padding;

      .row {
        align-items: center;

        h3 {
          font-size: $page-title-font-size;
          margin-bottom: $page-title-margin-bottom;
          font-weight: $page-title-font-weight;
          text-transform: $page-title-text-tranform;

          small {
            display: $page-small-title-display;
            font-size: $page-small-title-font-size;
            margin-bottom: $page-small-title-margin-bottom;
            text-transform: $page-small-title-text-transform;
            color: $theme-body-sub-title-color;
          }
        }
      }

      .breadcrumb {
        background-color: $transparent-color;
        padding: 0;
        margin-top: 5px;
        margin-bottom: 0;

        li {
          color: $black;
          font-family: $font-nunito;
          font-size: 14px;
          font-weight: 700;
          letter-spacing: 1px;
        }

        .breadcrumb-item {
          & + .breadcrumb-item {
            &::before {
              content: $breadcrumb-content;
            }
          }

          a {
            color: $theme-body-font-color;
            font-family: $font-nunito;

            svg {
              width: $breadcrumb-size;
              height: $breadcrumb-size;
            }
          }
        }
      }
    }
  }
}

.onhover-dropdown {
  cursor: pointer;
  position: relative;

  &:hover {
    .onhover-show-div {
      @extend %for-animated-hover-box;
    }
  }
}

.onhover-show-div {
  top: 80px;
  position: absolute;
  z-index: 7;
  background-color: $white;
  transition: all linear 0.3s;

  @extend %for-animated-hover;

  li {
    a {
      svg {
        margin-top: 0 !important;

        path,
        line {
          color: $theme-body-font-color !important;
        }
      }
    }
  }
}

/**  ======Main Header css Ends ======  **/
.scorlled {
  .page-sidebar {
    top: 0 !important;
    height: 100vh !important;
  }
}

/* ========sidebar icon vertical menu start======= */

.compact-switch {
  display: none;
}

.sidebar-toggle-btn {
  font-size: 18px;
  margin-left: 10px;
  cursor: pointer;
}

/* ========sidebar icon vertical menu end======= */

.header-logo {
  margin-right: 20px;

  &.normal {
    .normallogo {
      max-height: 40px;
      mix-blend-mode: screen;
    }
  }
}

/**  =====================
    05. Header CSS Ends
==========================  **/
