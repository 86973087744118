.edit-user-page {
  margin: 0px 20px;

  .breadcrumb-list {
    font-family: Raleway;
    font-weight: 600;
    font-size: 14px;
    line-height: 16.8px;
    cursor: pointer;
    color: #50535b;
  }

  .back-button {
    width: 34px;
    height: 34px;
    background: #eff1f6;
    border-radius: 6px;
    padding: 8px 16px;
  }

  .user-header-heading {
    font-family: Raleway;
    font-weight: 700;
    font-size: 28px;
    line-height: 28px;
    letter-spacing: 0%;
    color: #252933;
  }

  .user-sub-heading {
    font-family: Raleway;
    font-weight: 600;
    font-size: 14px;
    line-height: 19.6px;
    letter-spacing: 0%;
    color: #50535b;
  }

  .user-general-fields {
    background: #ffffff;
    border: 1px solid #eff1f6;
    border-radius: 12px;
    padding: 48px 20px;

    .user-fields-heading {
      font-family: Raleway;
      font-weight: 700;
      font-size: 20px;
      line-height: 100%;
      color: #323232;
    }
  }

  .user-common-input .rbt-input {
    border-radius: 4px;
    padding-top: 8px;
    padding-right: 12px;
    padding-bottom: 8px;
    padding-left: 12px;
    font-weight: 600;
    border: 1px solid #9fadc9 !important;
    background: #ffffff;
    color: #2c4474 !important;
  }

  .user-common-input .rbt-input::placeholder {
    font-family: Raleway;
    font-weight: 600;
    font-size: 14px;
    line-height: 19.6px;
    color: #9fadc9 !important;
  }

  .user-common-input .form-label {
    font-family: Raleway;
    font-weight: 600;
    font-size: 14px;
    line-height: 19.6px;
    color: #50535b !important;
  }

  .user-common-input .form-control {
    border-radius: 4px;
    padding-top: 8px;
    padding-right: 12px;
    padding-bottom: 8px;
    padding-left: 12px;
    font-weight: 600;
    border: 1px solid #9fadc9 !important;
    background: #ffffff;
    color: #2c4474 !important;
  }

  .user-common-input .form-control::placeholder {
    font-family: Raleway;
    font-weight: 600;
    font-size: 14px;
    line-height: 19.6px;
    color: #9fadc9 !important;
  }

  .user-common-input .form-label {
    font-family: Raleway;
    font-weight: 600;
    font-size: 14px;
    line-height: 19.6px;
    color: #50535b !important;
  }

  .currency-text {
    font-family: Raleway;
    font-weight: 600;
    font-size: 12px;
    line-height: 16.8px;
    color: #2c4474;
  }

  .user-common-input .btn-outline-select {
    border: 1px solid #9fadc9 !important;
  }
}
