.program-listing-wrapper {
  background: #ffffff;
  border: 1px solid #eff1f6;
  padding: 32px 20px;
  border-radius: 12px;
  margin: 0px 16px;
  box-shadow: 0 0 15px 10px #f7f7f7;

  .program-heading {
    font-family: Raleway;
    font-weight: 700;
    font-size: 20px;
    color: #323232;
  }

  .program-tagline {
    font-family: Raleway;
    font-weight: 600;
    font-size: 14px;
    line-height: 140%;
    letter-spacing: 0%;
    color: #50535b;
  }
}

.application-creation-btn {
  border-radius: 6px;
  background-color: #eff1f6 !important;
  color: #2c4474 !important;
  border: none !important;
}
