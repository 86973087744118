.client-users-wrapper {
  border-radius: 12px;
  border-width: 1px;
  background: #ffffff;
  border: 1px solid #eff1f6;
  padding: 32px 20px;

  .client-user-heading {
    font-family: Raleway;
    font-weight: 700;
    font-size: 20px;
    color: #323232;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 32px;

    .show-hide-text {
      font-family: Raleway;
      font-weight: 600;
      font-size: 14px;
      line-height: 16.44px;
      color: #2c4474;
    }
  }

  // .table-bordered th {
  //   background: #eff1f6;
  //   border: none;
  //   border-bottom: 1px solid #cfd6e4;
  // }

  // .table-bordered td {
  //   background: #ffffff;
  //   border-top: 1px solid #eff1f6;
  //   border-bottom: 1px solid #eff1f6;
  //   border-left: none;
  //   border-right: none;
  // }
}
